import React, {useContext} from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {AnimationType, notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {AnimationBehaviorContext} from '../Animations/Behavior/AnimationBehaviorHandler';
import LanguageCardsGame from './LanguageCardsGame';
import {Endpoints} from '../../endpoints';

document.body.style.overflow = 'hidden';

const LanguageCardsPage = ({language}) => {
  const animationContext = useContext(AnimationBehaviorContext);

  const infoEnglishCards = {
    name: 'EnglishOnCards',
    language: 'English',
    cards: [
      {
        name: {
          wordType: 'Verb',
          word: 'Spell',
          example: 'He has to _____ the word "Walk": W, A, L...',
          description: 'Say a word letter by letter',
        },
      },
    ],
  };

  const infoSpanishCards = {
    name: 'SpanishOnCards',
    language: 'Spanish',
    cards: [
      {
        name: {
          wordType: 'Verbo',
          word: 'Deletrear',
          example: 'El tiene que _____ la palabra "Caminar": C, A, M...',
          description: 'Decir una palabra letra por letra',
        },
      }, {
        name: {
          wordType: 'Adjetivo',
          word: 'Dificil',
          example: 'Es muy _____ ganar la lotería',
          description: 'Cuando algo es muy complicado de hacer, conseguir, o superar',
        },
      }, {
        name: {
          wordType: 'Sustantivo',
          word: 'Humanidad',
          example: 'Toda la _____ vive en el planeta Tierra',
          description: 'Conjunto que agrupa a todo ser humano.',
        },
      }, {
        name: {
          wordType: 'Sustantivo',
          word: 'Contenido',
          example: 'Dejame ver el _____ de esta caja',
          description: 'Lo que hay dentro de un objeto que puede ser abierto: caja, armario, cajón, nevera, etc.',
        },
      }, {
        name: {
          wordType: 'Adjetivo',
          word: 'Hermos@',
          example: 'Hoy pude ver una mariposa muy _____ en el jardín',
          description: 'Cuando algo o alguien es muy agradable visualmente',
        },
      }, {
        name: {
          wordType: 'Adverbio',
          word: 'Demasiado',
          example: '1. Tengo _____ trabajo por hacer.  2. En el aire hay _____ atomos de nitrógeno',
          description: 'Cuando algo se encuentra en inmensas cantidades',
        },
      }, {
        name: {
          wordType: 'Verbo',
          word: 'Estudiar',
          example: '1. Tengo que _____ para el examen o voy a reprobar el año.  2. _____ español',
          description: 'Practicar un tema o concepto con el fin de aprenderlo',
        },
      }, {
        name: {
          wordType: 'Verbo',
          word: 'Comer',
          example: 'Él tiene que _____ algo si quiere vivir',
          description: 'Ingerir algo con el proposito de alimentarse',
        },
      }, {
        name: {
          wordType: 'Verbo',
          word: 'Ir',
          example: 'Para poder _____ a Colombia necesitas un tiquete de avión',
          description: 'Desplazarse hacia algún lugar de cualquier manera',
        },
      }, {
        name: {
          wordType: 'Adjetivo',
          word: 'Importante',
          example: 'Ser feliz es lo más _____ de nuestra vida',
          description: 'Algo o alguien que es indispensable',
        },
      }, {
        name: {
          wordType: 'Sustantivo',
          word: 'Familia',
          example: 'Tendré que viajar para visitar a mi _____',
          description: 'Grupo de personas que tiene un proyecto de vida en común',
        },
      }, {
        name: {
          wordType: 'Sustantivo',
          word: 'Mujer',
          example: 'Ella es una _____ muy hermosa',
          description: 'Humano del género femenino',
        },
      },
    ],
  };

  const selectInfo = (language !== 'English') ? infoSpanishCards : infoEnglishCards;
  pageViewGA();

  return (
    <Wrapper onClick={(e) => {
      notPropagateEvent(e);
      if (animationContext.runningAny(AnimationType.Drag)) return;
      window.location.assign(Endpoints.desktopPage());
    }}>
      <Zoom x={40}>
        <LanguageCardsGame content={selectInfo}
          interactive={true}/>
      </Zoom>
    </Wrapper>
  );
};

const location = window.location.href.split('/');
LanguageCardsPage.defaultProps = {
  language: location[location.length - 1],
};

LanguageCardsPage.propTypes = {
  language: PropTypes.string,
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('resources/desk.jpg');
  background-color: #4e371f;
`;

const Zoom = styled.div`
  zoom: 1.5;
`;

export default LanguageCardsPage;
