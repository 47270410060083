import React from 'react';
import Login from './Login';
import {pageViewGA} from '../Common/Utils/analytics';
import PropTypes from 'prop-types';
import {emptyFunction} from '../Common/Utils/dumbUtils';

const LoginPage = ({setToken}) => {
  pageViewGA();

  return <>
    <Login setToken={setToken}/>
  </>;
};

LoginPage.defaultProps = {
  setToken: emptyFunction,
};

LoginPage.propTypes = {
  setToken: PropTypes.func,
};

export default LoginPage;
