import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chessboard from './model/Board';
import {gameBoard, initGame, resetGame} from './model/Game';
import {AnimationBehaviorContext} from '../Animations/Behavior/AnimationBehaviorHandler';
import {AnimationType, notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {Floating} from '../../Common/Components/Floating';
import {Endpoints} from '../../endpoints';

const Chess = ({interactive}) => {
  const animationContext = useContext(AnimationBehaviorContext);
  const onWrapperClicked = (e) => {
    notPropagateEvent(e);
    if (!interactive && !animationContext.runningAny(AnimationType.Drag)) {
      window.location.assign(Endpoints.chessPage());
    }
  };
  const [board, setBoard] = useState([]);
  const [gameOver, setGameOver] = useState();
  const [result, setResult] = useState();
  useEffect(() => {
    initGame();
    const subscribe = gameBoard.subscribe((game) => {
      setBoard(game.board);
      setGameOver(game.isGameOver);
      setResult(game.result);
    });
    return () => subscribe.unsubscribe(subscribe);
  }, []);

  return <Wrapper onClick={onWrapperClicked}>
    <Alert gameOver={gameOver}>
      <TextContainer>
        <h1>Game Over</h1>
      </TextContainer>
      <TextContainer>
        <h4>{result}</h4>
      </TextContainer>
      <TextContainer>
        <Button onClick={() => resetGame()} value={'New Game'} bg={'#808080'}/>
      </TextContainer>
    </Alert>
    <Chessboard interactive={interactive} board={board}/>
  </Wrapper>;
};

const Button = styled.button`
  background-color: #808080;
`;

const Wrapper = styled(Floating)`
  width: 500px;
  height: 500px;
  border: 5px black solid;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Alert = styled(Floating)`
  display: ${({gameOver}) => gameOver ? 'block' : 'none'};
  justify-content: center;
  width: 300px;
  height: 150px;
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 3px black solid;
  transition: 100ms;
  box-shadow: 
  inset 0 -3em 3em rgba(0,0,0,0.1),
        0 0  0 2px rgb(255,255,255),
        0.3em 0.3em 1em rgba(0,0,0,0.3);
`;

Chess.defaultProps = {
  interactive: false,
};

Chess.propTypes = {
  interactive: PropTypes.bool,
};

export default Chess;
