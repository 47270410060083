import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Grid, Tooltip} from '@material-ui/core';

const ComponentButton = ({id, onClick, componentName, componentType, isSelected}) => {
  return <Wrapper isSelected={isSelected}
    onClick={() => onClick()}>
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid item xs={12} className={'componentName'}>
          <Tooltip title={componentName}>
            <span>{componentName}</span>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <ComponentType>
            {componentType}
          </ComponentType>
        </Grid>
      </Grid>
    </Grid>
  </Wrapper>;
};

ComponentButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  componentType: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const ComponentType = styled.span`
  color: gray;
  font-size: 10px;
`;

export const Wrapper = styled.div`
  border: ${({isSelected}) => isSelected ?
    '2px green solid' :
    '1px gray solid'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  background: ${({isSelected}) => isSelected ?
    'ligthgray' :
    'white'};
  font-size: 20px;
  .componentName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :hover {
    background: #f1f1f1;
  }
`;

export default ComponentButton;
