import styled from 'styled-components';

export const Floating = styled.div.attrs(({x, y, z, w, h}) => ({
  style: {
    position: 'absolute',
    top: `${y !== undefined ? y : 50}%`,
    left: `${x !== undefined ? x : 50}%`,
    transform: 'translate(-50%, -50%)',
    zIndex: `${z ? z : '0'}`,
    width: `${w ? w : ''}`,
    height: `${h ? h : ''}`,
  },
}))``;
