export class NoStatusHandlerConfigured extends Error {
  constructor() {
    super();
    this.message = `Create AnimationBehaviorHandler to handle animations state`;
    this.name = 'NoStatusHandlerConfigured';
  }
}

export class DisabledAnimations extends Error {
  constructor(animation) {
    super();
    this.message = `Animation [${animation.toString()}] was not run. Animations are disabled`;
    this.name = 'DisabledAnimations';
  }
}

export class AnimationAlreadyRunningError extends Error {
  constructor(animation, runningAnimations) {
    const runningApplicationsStr = runningAnimations.map((a) => a.toString());
    super();
    this.message = `Animation [${animation.toString()}] was not run. Another animation is already executing: [${runningApplicationsStr}]`;
    this.name = 'AnimationAlreadyRunningError';
  }
}

export class NoAnimationsWhenDragging extends Error {
  constructor(animation, runningAnimations) {
    super();
    const runningApplicationsStr = runningAnimations.map((a) => a.toString());
    this.message = `Animation [${animation.toString()}] was not run. Animations are disabled while draging: [${runningApplicationsStr}]`;
    this.name = 'NoAnimationsWhenDragging';
  }
}

export class AnimationsIsNotRunning extends Error {
  constructor(animation, runningAnimations) {
    super();
    const runningApplicationsStr = runningAnimations.map((a) => a.toString());
    this.message = `Animation [${animation.toString()}] was not stopped. Animation was not running. Running Animations: [${runningApplicationsStr}]`;
    this.name = 'AnimationsIsNotRunning';
  }
}
