import React, {useContext} from 'react';
import {Grid, Input} from '@material-ui/core';
import styled from 'styled-components';
import AdminContext from './AdminEngine';

const CreateBusinessCardView = () => {
  const {currentValues, isVerified, updateField} = useContext(AdminContext);

  const checkValues = () => {
    if (currentValues.name &&
      currentValues.name !== '' &&
      currentValues.componentName &&
      currentValues.componentName !== '' &&
      currentValues.urlImageLogo &&
      currentValues.urlImageLogo !== '' &&
      currentValues.profession &&
      currentValues.profession !== '') {
      isVerified(true);
    } else {
      isVerified(false);
    }
  };

  return <Wrapper>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <FieldContainer border componentName>
            <FieldName>
              COMPONENT NAME:
            </FieldName>
            <FieldValue type={'text'}
              placeholder={'Some component name'}
              value={currentValues.componentName || ''}
              onChange={(e) => {
                updateField('componentName', e.target.value);
                checkValues();
              }}/>
          </FieldContainer>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            URL IMAGE:
          </FieldName>
          <FieldValue type={'text'}
            placeholder={'Some url image'}
            value={currentValues.urlImageLogo || ''}
            onChange={(e) => {
              updateField('urlImageLogo', e.target.value);
              checkValues();
            }}/>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            NAME:
          </FieldName>
          <FieldValue type={'text'}
            placeholder={'Some name'}
            value={currentValues.name || ''}
            onChange={(e) => {
              updateField('name', e.target.value);
              checkValues();
            }}/>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            PROFESSION:
          </FieldName>
          <FieldValue type={'text'}
            placeholder={'Some profession'}
            value={currentValues.profession || ''}
            onChange={(e) => {
              updateField('profession', e.target.value);
              checkValues();
            }}/>
        </FieldContainer>
      </Grid>
    </Grid>
  </Wrapper>;
};

const Wrapper = styled.div`
  font-size: 20px;
`;

const FieldContainer = styled.div`
  padding: ${({componentName}) => componentName ? '20' : '5'}px;
  border-bottom: ${({border}) => border ? '5' : '0'}px black solid;
  margin-right: 40px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
`;

const FieldName = styled.div`
  padding: 5px;
  margin-right: 8px;
  width: 35%;
`;

const FieldValue = styled(Input)`
  border: 1px gray solid;
  padding: 5px;
  width: 65%;
`;

export default CreateBusinessCardView;
