import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import {PageType} from './model';
import {CustomPropTypes} from '../../Common/Utils/customPropTypes';
import {notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {Content} from './Sheet';

const Cover = ({content, back}) => {
  return <Wrapper back={back}>
    <Floating>
      {content.type === PageType.EXTERNAL ?
        <Content onClick={(e) => {
          notPropagateEvent(e);
          window.location.assign(content.source);
        }}>
          <iframe src={content.source}/>
        </Content> :
        content.content}
    </Floating>
  </Wrapper>;
};

Cover.defaultProps = {
  back: false,
};

Cover.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.oneOf([PageType.DIRECT, PageType.EXTERNAL]).isRequired,
    content: CustomPropTypes.requiredIf('type', PageType.DIRECT),
    source: CustomPropTypes.requiredIf('type', PageType.EXTERNAL),
  },
  ).isRequired,
  back: PropTypes.bool,
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: url('resources/bookCover.jpg');
    background-size: cover;
    border-radius: ${({back}) => back ? '8px 0 0 8px' : '0 8px 8px 0'};
    background-color: #3b2779;
    font-family: cursive;
    font-size: 25px;
    color: #cccccc;
`;

export default Cover;
