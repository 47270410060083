import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {formatDouble, formatInteger} from '../../Common/Utils/formatter';

const MonthlyProfitSummaryTable = ({data}) => {
  const calculateTotalEquity = data.map((row) => row.profit).reduce((a, b) => a + b, 0);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stock Code</TableCell>
            <TableCell>Avg Bought Price</TableCell>
            <TableCell>Avg Sold Price</TableCell>
            <TableCell>No Shares</TableCell>
            <TableCell>Profit</TableCell>
            <TableCell>Profit Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) =>
            <TableRow key={index}>
              <TableCell>{row.stockCode}</TableCell>
              <TableCell>{formatDouble(row.averageBoughtPrice)} {row.currency}</TableCell>
              <TableCell>{formatDouble(row.averageSoldPrice)} {row.currency}</TableCell>
              <TableCell>{formatInteger(row.numberOfShares)}</TableCell>
              <TableCell>{formatDouble(row.profit)} {row.currency}</TableCell>
              <TableCell>{formatDouble(row.profitPercentage)} %</TableCell>
            </TableRow>,
          )}
          <TableRow>
            <TableCell colSpan={4} className={'text-right'}>Total Equity</TableCell>
            <TableCell>{formatDouble(calculateTotalEquity)}</TableCell>
            <TableCell/>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MonthlyProfitSummaryTable.defaultProps = {
  data: [],
};

MonthlyProfitSummaryTable.propTypes = {
  data:
    PropTypes.arrayOf(PropTypes.shape({
      stockCode: PropTypes.string.isRequired,
      averageBoughtPrice: PropTypes.number.isRequired,
      averageSoldPrice: PropTypes.number.isRequired,
      numberOfShares: PropTypes.number.isRequired,
      profit: PropTypes.number.isRequired,
      profitPercentage: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    })),
};

export default MonthlyProfitSummaryTable;
