import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {handleMove} from './Game';

const Square = ({children, black, location}) => {
  return <StyledSquare id={location} onDrop={(e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text');
    handleMove(data, e.target.id);
  }} onDragOver={(e) => e.preventDefault()}
  black={black}>
    {children}
  </StyledSquare>;
};

Square.propTypes = {
  location: PropTypes.string,
  black: PropTypes.bool,
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count > 1) {
      return new Error(`\`${componentName}\` should contain one children (${count} received)`);
    }
  },
};

const StyledSquare = styled.div`
  background: ${({black}) => (black) ? 'gray' : 'white'};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Square;
