import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import {Container, Grid} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import MonthlyProfitSummary from './MonthlyProfitSummary';

const MonthlyProfitSummaryPage = () => {
  pageViewGA();

  const {
    data: defaultAccount,
    loading: loadingDefaultAccount,
    error: getDefaultAccountFailed,
  } = useRetrieve(Endpoints.defaultAccount());

  return <Container>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1 className={'text-center'}>PROFIT SUMMARY</h1>
      </Grid>
      {defaultAccount && <MonthlyProfitSummary accountId={defaultAccount.id}/>}
    </Grid>
    {(loadingDefaultAccount) && <Progress/>}
    {getDefaultAccountFailed && <ErrorHandler error={getDefaultAccountFailed}/>}
  </Container>;
};

MonthlyProfitSummaryPage.defaultProps = {};

MonthlyProfitSummaryPage.propTypes = {};

export default MonthlyProfitSummaryPage;
