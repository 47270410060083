import React, {useRef, useState} from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import PropTypes from 'prop-types';
import PaperForm from './PaperForm';
import styled from 'styled-components';
import {notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {Ids} from '../ids';
import Draggable from '../Animations/Draggable';
import {Floating} from '../../Common/Components/Floating';
import useFormController from '../../Common/Services/useFormController';
import Progress from '../../Common/Components/Progress';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import usePageFormData from './usePageFormData';
import {Endpoints} from '../../endpoints';

const PaperFormPage = ({match}) => {
  pageViewGA();

  const {formContent} = usePageFormData(match.params.name);

  const {
    currentValues,
    loading,
    error,
    postForm,
    setField,
    clearValues,
  } = useFormController(formContent.destination);

  const drawerRef = useRef(null);
  const trashRef = useRef(null);

  const [dragging, setDragging] = useState(false);

  return <>
    <Wrapper onClick={(e) => {
      if (dragging) return;
      if (Object.entries(currentValues).length !== 0) alert('Save the changes before returning to the desktop!');
      else {
        notPropagateEvent(e);
        window.location.assign(Endpoints.desktopPage());
      }
    }}>
      <Zoom x={40}>
        <Draggable dragOver={{top: -60, bottom: 60, left: -100, right: 100}}
          onDragStart={() => setDragging(true)}
          onDragEnd={async (e) => {
            if (e.target === drawerRef.current) {
              await postForm(formContent.destination);
            } else if (e.target === trashRef.current) {
              await clearValues();
            }
          }}
          onDragged={() => setDragging(false)}
          id={Ids.paperForm(name)}>
          <PaperForm content={formContent} interactive={true} setField={setField} currentValues={currentValues}/>
        </Draggable>
      </Zoom>
    </Wrapper>
    <FastAccess x={95} y={50}>
      <Floating y={30}>
        <Drawer ref={drawerRef} dragging={dragging} onClick={async () => {
          await postForm(formContent.destination);
        }}/>
      </Floating>
      <Floating y={60}>
        <Trash ref={trashRef} dragging={dragging} onClick={clearValues}/>
      </Floating>
    </FastAccess>
    {loading && <Progress/>}
    {error && <ErrorHandler error={error}/>}
  </>;
};

PaperFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: url('resources/desk.jpg');
    background-color: #4e371f;
`;

const Zoom = styled.div`
    zoom: 2;
`;

// TODO: Fast Access should be a separated component. Probably in Utils since it is not an actual real object
const FastAccess = styled(Floating)`
    width: 120px;
    height: 100%;
    background-color: #ffffff;
    border: solid black 1px;
`;

// TODO: Drawer should be a separated component
export const Trash = styled.div`
    width: 100px;
    height: 100px;
    background: url('resources/trashCan.jpg');
    background-size: cover;
    cursor:pointer;
    :hover{
      border: solid black ${({dragging}) => (dragging === true) ? '1px' : '0px'};
    }
`;

// TODO: Drawer should be a separated component
export const Drawer = styled.div`
    width: 100px;
    height: 100px;
    background: url('resources/drawer.jpg');
    background-size: cover;
    cursor:pointer;
    :hover{
      border: solid black ${({dragging}) => (dragging === true) ? '1px' : '0px'};
    }
`;

export default PaperFormPage;
