import React, {useContext} from 'react';
import {Grid, IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useDelete from './../Common/Services/useDelete';
import Progress from './../Common/Components/Progress';
import ErrorHandler from './../Common/Components/ErrorHandler';
import {Endpoints} from './../endpoints';
import ConfirmNotification from '../Common/Components/ConfirmNotification';
import AdminContext from './AdminEngine';

const BusinessCardView = ({retrieve}) => {
  const {
    selectedComponent: id,
    unselectComponent,
    deletingComponent: deleting,
    isDeleting: setDeleting,
  } = useContext(AdminContext);

  const {
    deleting: deletingComponent,
    error: componentFailed,
    delete: deleteComponent,
  } = useDelete(Endpoints.deleteComponent(id), () => {
    retrieve();
    unselectComponent();
  }, () => {

  });

  const accept = () => {
    deleteComponent();
  };

  return <Wrapper>
    {deleting && <ConfirmNotification
      onAccept={accept}
      onDecline={() => setDeleting(false)}
      principalText={'Are you sure you want to remove this component?'}
      secondaryText={'This action is permanent.'}/>
    }
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <FieldContainer border componentName>
            <FieldName>
              COMPONENT NAME:
            </FieldName>
            <FieldValue>
              Some component name
            </FieldValue>
            <IconButton color={'secondary'} onClick={() => setDeleting(true)} id={`deleteComponent`}>
              <DeleteIcon/>
            </IconButton>
          </FieldContainer>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            URL IMAGE:
          </FieldName>
          <FieldValue>
            Some url image
          </FieldValue>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            NAME:
          </FieldName>
          <FieldValue>
            Some name
          </FieldValue>
        </FieldContainer>
      </Grid>
      <Grid item xs={12}>
        <FieldContainer>
          <FieldName>
            PROFESSION:
          </FieldName>
          <FieldValue>
            Some profession
          </FieldValue>
        </FieldContainer>
      </Grid>
    </Grid>
    {deletingComponent && <Progress/>}
    {componentFailed && <ErrorHandler error={componentFailed}/>}
  </Wrapper>;
};

BusinessCardView.propTypes = {
  retrieve: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  font-size: 20px;
  position: relative;
  width: 100%;
`;

const FieldContainer = styled.div`
  padding: ${({componentName}) => componentName ? '20' : '5'}px;
  border-bottom: ${({border}) => border ? '5' : '0'}px black solid;
  margin-right: 40px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
`;

const FieldName = styled.div`
  padding: 5px;
  margin-right: 8px;
  width: 35%;
`;

const FieldValue = styled.div`
  border: 1px gray solid;
  padding: 5px;
  width: 65%;
`;

export default BusinessCardView;
