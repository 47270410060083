import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import {Button, Container, Grid} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import StockJournal from './StockJournal';

const StockJournalPage = () => {
  pageViewGA();

  const {
    data: defaultAccount,
    loading: loadingDefaultAccount,
    error: getDefaultAccountFailed,
  } = useRetrieve(Endpoints.defaultAccount());

  return <Container>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1 className={'text-center'}>STOCKS JOURNAL</h1>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant="contained" color="primary"
          onClick={() => window.location.assign(Endpoints.stockTransactionsPage())}>
          Go to Transactions</Button>
      </Grid>
      {defaultAccount && <StockJournal accountId={defaultAccount.id}/>}
    </Grid>
    {(loadingDefaultAccount) && <Progress/>}
    {getDefaultAccountFailed && <ErrorHandler error={getDefaultAccountFailed}/>}
  </Container>;
};

StockJournalPage.defaultProps = {};

StockJournalPage.propTypes = {};

export default StockJournalPage;
