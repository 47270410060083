import React from 'react';
import {Floating} from '../../Common/Components/Floating';
import PropTypes from 'prop-types';
import Cover from './Cover';
import styled from 'styled-components';
import Sheet from './Sheet';
import PageableGroup from '../Animations/PageableGroup';
import {Ids} from '../ids';
import {CustomPropTypes} from '../../Common/Utils/customPropTypes';
import {PageType} from './model';

const Notebook = ({content}) => {
  const sheetCount = content.pages.length / 2 - 2;

  const pageContents = (page) => {
    return {front: content.pages[page * 2], back: content.pages[page * 2 + 1]};
  };

  const pageWidths = [320, ...Array(sheetCount).fill(317), 320];
  return <Wrapper>
    <Floating x={0}>
      <PageableGroup pageWidth={pageWidths} pageCount={sheetCount + 2} id={Ids.notebook(content.name)}>
        <>
          <WrapperCover>
            <Cover content={pageContents(0).front}/>
          </WrapperCover>
          <WrapperCover>
            <Cover content={pageContents(0).back} back={true}/>
          </WrapperCover>
        </>
        {Array.from(Array(sheetCount).keys()).map((page) =>
          <React.Fragment key={page}>
            <WrapperSheet>
              <Sheet content={pageContents(page + 1).front}/>
            </WrapperSheet>
            <WrapperSheet>
              <Sheet back={true} content={pageContents(page + 1).back}/>
            </WrapperSheet>
          </React.Fragment>,
        )}
        <>
          <WrapperCover>
            <Cover content={pageContents(sheetCount + 1).front}/>
          </WrapperCover>
          <WrapperCover>
            <Cover content={pageContents(sheetCount + 1).back} back={true}/>
          </WrapperCover>
        </>
      </PageableGroup>
    </Floating>
  </Wrapper>;
};

Notebook.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([PageType.DIRECT, PageType.EXTERNAL]).isRequired,
        content: CustomPropTypes.requiredIf('type', PageType.DIRECT),
        source: CustomPropTypes.requiredIf('type', PageType.EXTERNAL),
      }).isRequired).isRequired,
    pagesSize: CustomPropTypes.minSize('pages', 4),
  }).isRequired,
};

const Wrapper = styled(Floating)`
        width: 320px;
        height: 400px;
        `;

const WrapperCover = styled(Floating)`
        width: 320px;
        height: 400px;
        `;

const WrapperSheet = styled(Floating)`
        width: 316px;
        height: 396px;
        `;

export default Notebook;
