import React from 'react';
import PropTypes from 'prop-types';
import Flippable from '../Animations/Flippable';
import BackSide from './BackSide';
import FrontSide from './FrontSide';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import {Ids} from '../ids';

const PersonalCard = ({data}) => {
  return <Flippable id={Ids.PersonalCard}>
    <Wrapper>
      <FrontSide data={data}/>
    </Wrapper>
    <Wrapper>
      <BackSide/>
    </Wrapper>
  </Flippable>;
};

PersonalCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mote: PropTypes.string.isRequired,
    profession: PropTypes.string.isRequired,
  }).isRequired,
};

const Wrapper = styled(Floating)`
    width: 340px;
    height: 200px;
    border-radius: 2%;
`;

export default PersonalCard;
