import React from 'react';
import {Grid, InputAdornment, TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ColoredButton} from '../../Common/Components/styledComponents';
import useFormController from '../../Common/Services/useFormController';
import {Endpoints} from '../../endpoints';
import PropTypes from 'prop-types';
import Progress from '../../Common/Components/Progress';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import useRetrieve from '../../Common/Services/useRetrieve';
import {grey} from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import {formatDouble} from '../../Common/Utils/formatter';
import {Actions as Action, Actions} from './ActionsMenu';

const DividendTransactionForm = ({onDividendGotten, accountId, dataSync: {getDefault, handleData, reset}}) => {
  const {
    currentValues,
    loading: gettingDividendStock,
    error: dividendStockFailed,
    postForm: dividendTransaction,
    setField,
  } = useFormController(Endpoints.dividendTransaction(accountId), {
    currencyCode: 'PHP',
    stockCode: '',
    earningsPerShare: '',
    numberOfShares: '',
    dividendFee: '',
    dateEarned: (new Date()).toISOString().split('T')[0],
  }, getDefault(Actions.DIVIDEND), () => {
    onDividendGotten();
    reset();
  });
  const {
    data: stocks,
    loading: loadingStocks,
    error: getStocksFailed,
  } = useRetrieve(Endpoints.stockCodes());

  const onSetField = (field, value) => {
    handleData(field, value);
    setField(field, value);
  };

  const totalValue = (parseFloat(currentValues.earningsPerShare) * parseInt(currentValues.numberOfShares)) - parseFloat(currentValues.dividendFee);
  return <ImproveNumberFormatting>
    <Box m={2} p={1} bgcolor={grey[100]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} lg={10}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    name="Stock"
                    freeSolo
                    autoSelect
                    disableClearable
                    options={stocks ? stocks : ['']}
                    value={currentValues.stockCode}
                    onInputChange={(event, newValue) => {
                      onSetField('stockCode', newValue.toUpperCase());
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label={'Stock'}/>}
                  /></Grid>
                <Grid item xs={3} md={2}>
                  <TextField
                    label="# Shares"
                    name="# Shares"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currentValues.numberOfShares}
                    onChange={(e) => {
                      onSetField('numberOfShares', e.target.value);
                    }}/></Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    label="Per Share"
                    name="Per Share"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position={'end'}>{currentValues.currencyCode}</InputAdornment>,
                    }}
                    value={currentValues.earningsPerShare}
                    onChange={(e) => {
                      onSetField('earningsPerShare', e.target.value);
                    }}/></Grid>
                <Grid item xs={5} md={2}>
                  <TextField
                    label="Dividend Fee"
                    name="Dividend Fee"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position={'end'}>{currentValues.currencyCode}</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currentValues.dividendFee}
                    onChange={(e) => {
                      onSetField('dividendFee', e.target.value);
                    }}/></Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Date"
                    name="Date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={currentValues.dateEarned}
                    onChange={(e) => {
                      onSetField('dateEarned', e.target.value);
                    }}/></Grid>
              </Grid>
              <Grid item xs={12} lg={10}>
                <Typography align={'right'}>
                  {totalValue ? `TOTAL DIVIDEND: ${formatDouble(totalValue)}` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <ColoredButton
                variant="contained" colored={Action.getColor(Action.DIVIDEND)}
                onClick={dividendTransaction}>Get Dividend</ColoredButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    {gettingDividendStock || loadingStocks && <Progress/>}
    {dividendStockFailed && <ErrorHandler error={dividendStockFailed}/>}
    {getStocksFailed && <ErrorHandler error={getStocksFailed}/>}
  </ImproveNumberFormatting>;
};

DividendTransactionForm.defaultProps = {};

DividendTransactionForm.propTypes = {
  onDividendGotten: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  dataSync: PropTypes.shape({
    getDefault: PropTypes.func.isRequired,
    handleData: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }).isRequired,
};

const ImproveNumberFormatting = styled.div`
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}`;

export default DividendTransactionForm;
