import React from 'react';
import PropTypes from 'prop-types';
import LockingError from './LockingError';
import NotificationError from './NotificationError';
import ReLoginError from './ReLoginError';
import {Endpoints} from '../../endpoints';

const ErrorHandler = ({error}) => {
  const isControlledError = error.response && error.response.data && error.response.data.message &&
    (error.response.status === 400 || error.response.status === 409 ||
      (error.response.status === 401 && window.location.pathname === Endpoints.logInPage()));
  const isLoginOrAccessError = error.response &&
    ((error.response.status === 401 && window.location.pathname !== Endpoints.logInPage()) || error.response.status === 403);

  function parseErrors() {
    return error.response.data.errors ?
      Object.entries(error.response.data.errors).map((val) => `${val[0]} ${val[1]}`) :
      [error.response.data.message];
  }

  return <>
    {isLoginOrAccessError ?
      <ReLoginError/> : isControlledError ?
        <NotificationError title={error.response.data.message} errors={parseErrors()}/> :
        isLoginOrAccessError ?
          <ReLoginError/> :
          <LockingError error={error.message} message={JSON.stringify(error.response)}/>}
  </>;
};

ErrorHandler.defaultProps = {};

ErrorHandler.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.oneOfType([
        PropTypes.shape({
          error: PropTypes.string,
          status: PropTypes.string,
          message: PropTypes.string,
          errors: PropTypes.object,
        }),
        PropTypes.any,
      ]),
    }),
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default ErrorHandler;
