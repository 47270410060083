import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Piece = ({piece, position}) => {
  const [dragging, setDragging] = useState(false);
  const selectPiece = piece !== null ? `resources/ChessAssets/${piece.type}_${piece.color}.png` : null;

  return <ChessPiece isDragging={dragging}
    draggable={piece !== null}
    onDragStart={(e) => {
      const img = new Image();
      img.src = selectPiece;
      e.dataTransfer.setData('text', e.target.id);
      e.dataTransfer.setDragImage(img, 20, 20);
      setDragging(true);
    }}
    onDragEnd={() => setDragging(false)}>
    <ChessImage id={position} alt={''} src={selectPiece} isDragging={dragging}/>
  </ChessPiece>;
};

Piece.propTypes = {
  position: PropTypes.string,
  piece: PropTypes.shape({
    type: PropTypes.string,
    color: PropTypes.string,
  }),
};

const ChessPiece = styled.div`
  cursor: grab;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({isDragging}) => isDragging ? 0 : 1};
  ::selection {
    background: #ffffff00;
  }
`;

const ChessImage = styled.img`
  max-width: 70%;
  max-height: 70%;
  ::selection {
    background: #ffffff00;
  }
`;

export default Piece;
