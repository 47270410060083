import React, {createContext, useState} from 'react';
import useFormController from '../Common/Services/useFormController';
import useRetrieve from '../Common/Services/useRetrieve';
import {Endpoints} from '../endpoints';
import useFormDataSync from '../Stocks/Services/useFormDataSync';

const AdminContext = createContext();

const AdminContextProvider = ({children}) => {
  const [deletingComponent, setDeletingComponent] = useState(false);
  const [creatingComponent, setCreatingComponent] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [verified, setVerified] = useState(false);
  const {dataSync} = useFormDataSync();

  const {
    data: componentsData,
    loading: loadingComponents,
    error: getComponentsFailed,
    refresh: refreshComponents,
  } = useRetrieve(Endpoints.components());


  const {
    currentValues,
    setField,
    postForm,
    clearValues,
  } = useFormController(Endpoints.createComponent(), {
    type: 'BUSINESS_CARD',
    componentName: '',
    name: '',
    urlImageLogo: '',
    profession: '',
  }, dataSync.getDefault('CREATE_BUSINESS_CARD'), (res) => {
    refreshComponents();
    setCreatingComponent(false);
    setSelectedComponent(res.data.id);
  });

  const isDeleting = (newStatus) => {
    setDeletingComponent(newStatus);
  };

  const isCreating = (newStatus) => {
    setCreatingComponent(newStatus);
  };

  const selectComponent = (id) => {
    setSelectedComponent(id);
    isDeleting(false);
    isCreating(false);
    setVerified(false);
  };

  const unselectComponent = () => {
    setSelectedComponent(null);
    isDeleting(false);
    clearValues();
    setVerified(false);
  };

  const isVerified = (newStatus) => {
    setVerified(newStatus);
  };

  const updateField = (prop, value) => {
    setField(prop, value);
    dataSync.handleData(prop, value);
  };

  const contextValue = {
    componentsData,
    loadingComponents,
    getComponentsFailed,
    refreshComponents,
    deletingComponent,
    isDeleting,
    selectedComponent,
    creatingComponent,
    isCreating,
    verified,
    isVerified,
    selectComponent,
    unselectComponent,
    currentValues,
    updateField,
    postForm,
    dataSync,
  };

  return <AdminContext.Provider value={contextValue}>
    {children}
  </AdminContext.Provider>;
};

AdminContextProvider.propTypes = {
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count === 0 || count > 1) {
      return new Error(`\`${componentName}\` should contain one children (${count} received)`);
    }
  },
};

export default AdminContext;
export {AdminContextProvider};
