import React from 'react';
import PropTypes from 'prop-types';
import FrontSide from './FrontSide';
import BackSide from './BackSide';
import Flippable from '../Animations/Flippable';
import {Ids} from '../ids';

const GameCard = ({content, language}) => {
  return <Flippable id={Ids.GameCard}>
    <FrontSide front={content} language={language}/>
    <BackSide back={content}/>
  </Flippable>;
};

GameCard.propTypes = {
  content: PropTypes.shape({
    wordType: PropTypes.string.isRequired,
    example: PropTypes.string.isRequired,
    word: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default GameCard;
