import React, {useState} from 'react';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import Button from './Button';
import Display from './Display';

const CalculatorFunction = () => {
  const [toCalcule, setToCalcule] = useState({
    operator: null,
    waitingForOperand: false,
  });
  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState('0');

  const scale = (displayValue.length < 13) ? 40 : (displayValue.length < 16) ? 34 : 25;

  const operations = (prevValue, nextValue, operator) => {
    if (operator === '/') {
      return prevValue / nextValue;
    } else if (operator === '*') {
      return prevValue * nextValue;
    } else if (operator === '+') {
      return prevValue + nextValue;
    } else if (operator === '-') {
      return prevValue - nextValue;
    } else {
      return nextValue;
    }
  };

  const clearCalculator = () => {
    setToCalcule({
      operator: null,
      waitingForOperand: false,
    });
    setValue(null);
    setDisplayValue('0');
  };

  const clearDisplay = () => {
    setDisplayValue('0');
  };

  const changeSign = () => {
    const newValue = parseFloat(displayValue) * -1;
    if (!toCalcule.waitingForOperand) {
      setDisplayValue(newValue.toString());
    }
  };

  const instertDot = () => {
    const operator = toCalcule.operator;

    if (!(/\./).test(displayValue) && !toCalcule.waitingForOperand) {
      setToCalcule({
        waitingForOperand: false,
        operator: operator,
      });
      setDisplayValue(displayValue + '.');
    } else if (toCalcule.waitingForOperand) {
      setToCalcule({
        waitingForOperand: false,
        operator: operator,
      });
      setDisplayValue('0.');
    }
  };

  const getNum = (num) => {
    const waitingForOperand = toCalcule.waitingForOperand;
    const operator = toCalcule.operator;

    if (displayValue.length < 15 || waitingForOperand) {
      if (waitingForOperand) {
        setToCalcule({
          waitingForOperand: false,
          operator: operator,
        });
        setDisplayValue(num.toString());
      } else {
        setDisplayValue((displayValue === '0') ? num.toString() : displayValue + num);
      }
    }
  };

  const getFunc = (func) => {
    const operator = toCalcule.operator;
    const showedValue = parseFloat(displayValue);

    if (value === null) {
      setValue(showedValue);
    } else {
      const currentValue = value || 0;
      const newValue = operations(currentValue, showedValue, operator);
      setDisplayValue(newValue.toString());
      setValue(newValue);
    }

    setToCalcule({
      waitingForOperand: true,
      operator: func,
    });
  };

  return <Wrapper>
    <Up color={'black'}><Display fSize={scale.toString()} value={displayValue}/></Up>
    <KeyBoard>
      <tbody>
        <tr>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(7)} value={'7'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(8)} value={'8'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(9)} value={'9'}/></Box>
          <Box><Button bg={'#ffc107'} action={() => clearDisplay()} value={'C'}/></Box>
          <Box><Button bg={'#E74C3C'} action={() => changeSign()} value={'+/-'}/></Box>
        </tr>
        <tr>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(4)} value={'4'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(5)} value={'5'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(6)} value={'6'}/></Box>
          <Box><Button bg={'#ffc107'} action={() => getFunc('*')} value={'x'}/></Box>
          <Box><Button bg={'#ffc107'} action={() => getFunc('/')} fSize={'20px'} value={'÷'}/></Box>
        </tr>
        <tr>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(1)} value={'1'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(2)} value={'2'}/></Box>
          <Box><Button bg={'#292929'} color={'white'} action={() => getNum(3)} value={'3'}/></Box>
          <Box><Button bg={'#ffc107'} action={() => getFunc('+')} value={'+'}/></Box>
          <Box><Button bg={'#ffc107'} action={() => getFunc('-')} fSize={'20px'} value={'-'}/></Box>
        </tr>
        <tr>
          <Box colSpan="2"><Button bg={'#ffffff'} action={() => getNum(0)} fSize={'20px'} value={'0'}/></Box>
          <Box><Button action={() => instertDot()} bg={'#ffffff'} fSize={'30px'} value={'.'}/></Box>
          <Box><Button action={() => clearCalculator()} bg={'#ffffff'} value={'AC'}/></Box>
          <Box><Button bg={'#E67E22'} action={() => getFunc('=')} fSize={'20px'} value={'='}/></Box>
        </tr>
      </tbody>
    </KeyBoard>
  </Wrapper>;
};

const Wrapper = styled(Floating)`
  box-sizing: border-box;
  margin:0;
  padding: 0;
  width: 340px;
  height: 400px;
  border: 5px black solid;
  border-radius: 10px;
  background: #7e7c7d;
  display: block;
`;

const Up = styled.div`
  display: flex;
  background: ${({color}) => color}
`;

const KeyBoard = styled.table`
  width: 280px;
  height: 260px;
  margin: auto;
  margin-top: 10px;
`;

const Box = styled.td`
  width: 20%;
  height: 37.14px;
`;

export default CalculatorFunction;
