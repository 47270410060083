import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import {AnimationType, notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {AnimationBehaviorContext} from '../Animations/Behavior/AnimationBehaviorHandler';
import {CustomPropTypes} from '../../Common/Utils/customPropTypes';
import {emptyFunction} from '../../Common/Utils/dumbUtils';
import {Endpoints} from '../../endpoints';

const PaperForm = ({content, interactive, setField, currentValues}) => {
  const animationContext = useContext(AnimationBehaviorContext);
  const onWrapperClicked = (e) => {
    notPropagateEvent(e);
    if (!interactive && !animationContext.runningAny(AnimationType.Drag)) {
      window.location.assign(Endpoints.paperFormPage(content.name));
    }
  };

  const onInputFocused = (e) => {
    notPropagateEvent(e);
    animationContext.enable(false);
  };

  const onInputBlurred = (e) => {
    notPropagateEvent(e);
    animationContext.enable(true);
  };

  return <Wrapper height={(content.fields.length + 1) * 40} onClick={onWrapperClicked}>
    <Title x={50} y={12}>{content.name}</Title>
    {content.fields.map((field, index) =>
      <React.Fragment key={index}>
        <Field x={20} y={(index + 1) * 17 + 7}>
          {field.text}
        </Field>
        <InputWrapper x={70} y={(index + 1) * 17 + 7} onFocus={onInputFocused} onBlur={onInputBlurred}>
          {interactive &&
          <Input
            name={field.name}
            onChange={(e) => setField(field.name, e.target.value)}
            value={currentValues[field.name] !== undefined ? currentValues[field.name] : ''}/>}
        </InputWrapper>
      </React.Fragment>,
    )}
  </Wrapper>;
};

PaperForm.defaultProps = {
  interactive: false,
  setField: emptyFunction,
  currentValues: {},
};

PaperForm.propTypes = {
  content: PropTypes.shape(
    {
      name: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      fieldsLength: CustomPropTypes.minSize('fields', 1),
    },
  ).isRequired,
  interactive: PropTypes.bool,
  setField: PropTypes.func,
  currentValues: PropTypes.object,
};

const Title = styled(Floating)`
    font-weight: bold
`;

const Field = styled(Floating)`
`;

const InputWrapper = styled(Floating)`
    height: 20px;
    width: 50%;
    background-color: #DDDDDD;
`;

export const Input = styled.input`
    width: 100%;
`;

const Wrapper = styled(Floating)`
    width: 350px;
    height: ${({height}) => height}px;
    border-radius: 4px};
    background: url('resources/paper.jpg');
    background-size: cover;
    background-color: white;
    text-align: center;
`;

export default PaperForm;
