import React, {useState} from 'react';
import {Floating} from './Floating';
import PropTypes from 'prop-types';
import {SnackbarContent} from '@material-ui/core';

const NotificationError = ({title, errors}) => {
  const [visible, setVisible] = useState(true);

  return <>{visible && <Floating x={50} y={89} onClick={() => {
    setVisible(false);
  }}>
    <SnackbarContent
      message={<>
        <strong>{title}</strong>
        <br/>
        {errors.map((field, index) => <div key={index}>{field}</div>)}
      </>}
    />
  </Floating>
  }</>;
};

NotificationError.defaultProps = {
  errors: [],
};

NotificationError.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default NotificationError;
