import {Endpoints} from '../../endpoints';

const usePageFormData = (name) => {
  // TODO: this will be replaced by server retrieved data.
  //  Change match.params.name to match.params.id and retrieve the whole object with it
  const formContent = {
    name,
    fields: [
      {text: 'Bought In Date', name: 'dateBought'},
      {text: 'Stock', name: 'stockCode'},
      {text: 'Cost Per Share', name: 'buyPrice'},
      {text: 'Amount', name: 'numberOfShares'},
      {text: 'Currency', name: 'currencyCode'},
    ],
    destination: Endpoints.buyStockTransaction('defaultId'),
  };


  return {
    formContent,
  };
};

export default usePageFormData;
