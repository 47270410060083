import React from 'react';
import {Floating} from './Floating';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Button, SnackbarContent} from '@material-ui/core';

const GeneralError = ({title, error, message, actionName, action}) => {
  const parseMessage = () => {
    try {
      return JSON.stringify(JSON.parse(message), null, 2);
    } catch (e) {
      return message;
    }
  };

  return (
    <Wrapper z={3}>
      <Floating x={50} y={50} z={4} w={'80%'}>
        <SnackbarContent
          message={
            <div>
              <div>{title}</div>
              <br/>
              <small>{error}</small>
              <ErrorDetail>
                <pre>
                  {(parseMessage)()}
                </pre>
              </ErrorDetail>
              <div/>
            </div>}
          action={
            <Button color="secondary" size="small" onClick={action}>
              {actionName}
            </Button>}/>
      </Floating>
    </Wrapper>
  );
};

GeneralError.defaultProps = {
  title: 'Error',
  error: 'Unknown',
  message: '',
  actionName: 'Refresh',
  action: () => window.location.reload(),
};

GeneralError.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  message: PropTypes.string,
  actionName: PropTypes.string,
  action: PropTypes.func,
};

const Wrapper = styled(Floating)`
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.8);

    .MuiSnackbarContent-message {
      width: 100%;
    }
`;

const ErrorDetail = styled.div`
    width: 100%;
    max-height: 100px;
    overflow:auto;
    background-color: white;
    color: grey;
    text-align: left;
    
    pre {
      margin: 0;
    }
`;

export default GeneralError;
