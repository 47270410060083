import React from 'react';
import GeneralError from './GeneralError';
import {Endpoints} from '../../endpoints';

const ReLoginError = () => {
  return (
    <GeneralError title={'Expired'}
      error={'Authentication error. Please login again'}
      actionName={'Login Again'}
      action={() => window.location.assign(Endpoints.logOutPage())}/>
  );
};

ReLoginError.defaultProps = {};

ReLoginError.propTypes = {};

export default ReLoginError;
