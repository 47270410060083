import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './env.js';
import App from './App';
import * as serviceWorker from './Common/Utils/serviceWorker';
import {format} from 'util';
import {startMock} from './__tests/serviceMock';
import {env} from './env';
import disableScroll from 'disable-scroll';

disableScroll.on();

const error = global.console.error;
// Solution to fail when react Warning comes https://github.com/facebook/jest/issues/6121
global.console.error = function(...args) {
  error(...args);
  throw new Error(format(...args));
};

if (env.MOCKED === 'true') {
  startMock();
} else {
  console.log(env);
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
