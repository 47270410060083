import useAxios from 'axios-hooks';
import {useContext, useState} from 'react';
import {emptyFunction} from '../Utils/dumbUtils';
import {isNil, omitBy} from 'lodash';
import {userContext} from '../../Login/useToken';

const useFormController = (url, defaultValue = {}, synchronizedValues, onSuccess = emptyFunction, onError = emptyFunction) => {
  const [currentValues, setValues] = useState({...defaultValue, ...omitBy(synchronizedValues, isNil)});
  const token = useContext(userContext);
  const authHeader = {
    'Authorization': 'Bearer ' + token,
  };

  const [{loading, error}, post] =
    useAxios({
      url: url,
      method: 'POST',
      headers: {...authHeader},
    }, {manual: true, useCache: false});

  const postForm = () => {
    // TODO: validate currentValues (type and formatting) here before calling the backend
    post({
      data: currentValues,
    }).then((response) => {
      clearValues();
      onSuccess(response);
    }).catch((error) => {
      onError(error);
    });
  };

  const clearValues = () => {
    setValues(defaultValue);
  };

  const setField = (field, value) => {
    currentValues[field] = value;
    setValues({...currentValues});
  };

  return {
    currentValues,
    loading,
    error,
    postForm,
    setField,
    clearValues,
  };
};

export default useFormController;
