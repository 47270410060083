import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {Floating} from '../../Common/Components/Floating';
import {AnimationType, RunningAnimation} from './Behavior/behaviorDefinitions';
import {AnimationBehaviorContext} from './Behavior/AnimationBehaviorHandler';
import {motion} from 'framer-motion';
import styled from 'styled-components';

const Scrollable = ({id, contentWidth, contentHeight, onScrolled, onScrollStart, children}) => {
  const [status, setStatus] = useState({
    pos: {x: 0, y: 0},
    stepSize: 20,
    animating: false,
    direction: 0,
    type: '',
  });
  const [lastTouch, setLastTouch] = useState(null);
  const [moveScreen, setMoveScreen] = useState(null);
  const animationContext = useContext(AnimationBehaviorContext);
  const animation = new RunningAnimation(AnimationType.Scroll, id);
  document.body.style.overflow = 'hidden';

  const updatePosition = (direction, stepSize) => {
    let newX = Math.round(status.pos.x - stepSize * Math.cos(direction));
    let newY = Math.round(status.pos.y - stepSize * Math.sin(direction));
    const minY = -contentHeight / 2 + document.documentElement.clientHeight / 2;
    const maxY = contentHeight / 2 - document.documentElement.clientHeight / 2;
    const minX = -contentWidth / 2 + document.documentElement.clientWidth / 2;
    const maxX = contentWidth / 2 - document.documentElement.clientWidth / 2;
    if (document.documentElement.clientHeight > contentHeight) newY = 0; else if (newY < minY) newY = minY; else if (newY > maxY) newY = maxY;
    if (document.documentElement.clientWidth > contentWidth) newX = 0; else if (newX < minX) newX = minX; else if (newX > maxX) newX = maxX;

    return {x: newX, y: newY};
  };

  function continueAnimation() {
    if (status.animating) {
      setStatus({...status, pos: updatePosition(status.direction, status.stepSize)});
    }
  }

  function startAnimation(direction, type, stepSize) {
    if (!status.animating && animationContext.allowed(animation)) {
      const pos = updatePosition(direction, stepSize);
      const animating = pos.x !== status.pos.x || pos.y !== status.pos.y;
      if (animating) {
        onScrollStart();
        animationContext.start(animation);
      }
      setStatus({
        ...status,
        animating: animating,
        direction: direction,
        type: type,
        stepSize: stepSize,
        pos: pos,
      });
    }
  }

  function stopAnimation() {
    if (status.animating) {
      onScrolled();
      animationContext.stop(animation);
      setStatus({...status, scrolling: '', animating: false});
    }
  }

  function speedUpAnimation() {
    setStatus({...status, stepSize: 30});
  }

  function speedDownAnimation() {
    setStatus({...status, stepSize: 10});
  }

  return <Wrapper onWheel={(e) => startAnimation(Math.atan2(e.deltaY, e.deltaX), 'scroll', 20)}
    onMouseDown={(e) => {
      setMoveScreen(e.target.parentElement.parentElement.id === id);
    }}
    onMouseMove={(e) => {
      if (moveScreen && e.nativeEvent.buttons === 1 && lastTouch !== null) {
        startAnimation(Math.atan2(lastTouch.clientY - e.nativeEvent.y, lastTouch.clientX - e.nativeEvent.x), 'touch', 30);
      }
      if (moveScreen) {
        setLastTouch({clientX: e.nativeEvent.x, clientY: e.nativeEvent.y});
      }
    }}
    onTouchStart={(e) => {
      if (e.touches.length === 1) {
        setLastTouch(e.touches[0]);
      }
    }}
    onTouchMove={(e) => {
      if (e.touches.length === 1) {
        startAnimation(Math.atan2(lastTouch.clientY - e.touches[0].clientY, lastTouch.clientX - e.touches[0].clientX), 'touch', 20);
        setLastTouch(e.touches[0]);
      }
    }}
    onTouchEnd={(e) => {
      e.preventDefault();
      e.target.click();
    }}>
    <Vertical x={0} z={1} data-testid={'left'}
      onMouseDown={() => speedUpAnimation()}
      onMouseUp={() => speedDownAnimation()}
      onMouseEnter={() => startAnimation(Math.PI, 'border', 10)}
      onMouseLeave={() => stopAnimation()}
    />
    <Vertical x={100} z={1} data-testid={'right'}
      onMouseDown={() => speedUpAnimation()}
      onMouseUp={() => speedDownAnimation()}
      onMouseEnter={() => startAnimation(0, 'border', 10)}
      onMouseLeave={() => stopAnimation()}
    />
    <Horizontal y={0} z={1} data-testid={'top'}
      onMouseDown={() => speedUpAnimation()}
      onMouseUp={() => speedDownAnimation()}
      onMouseEnter={() => startAnimation(Math.PI * 3 / 2, 'border', 10)}
      onMouseLeave={() => stopAnimation()}
    />
    <Horizontal y={100} z={1} data-testid={'bottom'}
      onMouseDown={() => speedUpAnimation()}
      onMouseUp={() => speedDownAnimation()}
      onMouseEnter={() => startAnimation(Math.PI / 2, 'border', 10)}
      onMouseLeave={() => stopAnimation()}
    />
    <Floating>
      <motion.div data-testid={'scrollable'} id={id}
        initial={{translateX: status.pos.x, translateY: status.pos.y}}
        animate={{translateX: status.pos.x, translateY: status.pos.y}}
        transition={{duration: 0.01, type: 'tween'}}
        onAnimationComplete={() => {
          if (status.type === 'border') {
            continueAnimation();
          } else {
            stopAnimation();
          }
        }}
      >
        <div id={id}>{children}</div>
      </motion.div>
    </Floating>
  </Wrapper>;
};

Scrollable.defaultProps = {
  id: `Scrollable-${new Date().getTime()}`,
  onScrolled: () => null,
  onScrollStart: () => null,
};

Scrollable.propTypes = {
  id: PropTypes.string,
  onScrolled: PropTypes.func,
  onScrollStart: PropTypes.func,
  contentWidth: PropTypes.number.isRequired,
  contentHeight: PropTypes.number.isRequired,
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count !== 1) {
      return new Error(`\`${componentName}\` should contain one children (${count} received)`);
    }
  },
};

const Wrapper = styled(Floating)`
width: 100vw;
height: 100vh;
`;

const Vertical = styled(Floating)`
width: 0px;
height: 100%;
background-color: black;

@media only screen and (min-width: 641px) {
  width: 20px;
};
@media only screen and (min-width: 1021px) {
  width: 30px;
}
`;

const Horizontal = styled(Floating)`
  width: 100%;
  height: 0px;
  background-color: black;

  @media only screen and (min-width: 641px) {
    height: 20px;
  };
  @media only screen and (min-width: 1021px) {
    height: 30px;
  }
`;

export default Scrollable;
