import {makeStyles} from '@material-ui/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import {Button} from '@material-ui/core';

export const simpleBorder = makeStyles(() => ({
  root: {
    border: '1px solid black',
  },
}));

export const ColoredButton = withStyles((theme) => ({
  root: ({colored}) => ({
    'color': theme.palette.getContrastText(colored[500]),
    'backgroundColor': colored[500],
    '&:hover': {
      backgroundColor: colored[700],
    },
  }),
}))(Button);
