import React from 'react';
import {Floating} from '../../Common/Components/Floating';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BackSide = ({back}) => {
  return <Wrapper>
    <Floating>
      <p>{back.word}</p>
    </Floating>
  </Wrapper>;
};

BackSide.propTypes = {
  back: PropTypes.shape({
    word: PropTypes.string,
  }).isRequired,
};

const Wrapper = styled(Floating)`
  width: 340px;
  height: 200px;
  font-family: alibaba-sans;
  font-size: 50px;
  background: #e1e1e1;
  border: 5px #283a4e solid;
  border-radius: 10px;
  color: #000;
  text-transform: uppercase;
`;

export default BackSide;
