import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Square from './Square';
import {move} from './Game';

const Promote = ({promotion}) => {
  const promotionPieces = ['r', 'n', 'b', 'q'];
  return <Board>
    {promotionPieces.map((piece, index) => <PromoteSquare key={index}>
      <Square black={index % 3 === 0}>
        <ChessPiece onClick={() => {
          move(promotion.from, promotion.to, piece);
        }}>
          <ChessImage alt={''} src={`resources/ChessAssets/${piece}_${promotion.color}.png`}/>
        </ChessPiece>
      </Square>
    </PromoteSquare>)}
  </Board>;
};

Promote.propTypes = {
  promotion: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    color: PropTypes.string,
  }),
};

const Board = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background: white;
`;

const PromoteSquare = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChessPiece = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChessImage = styled.img`
  max-width: 70%;
  max-height: 70%;
  ::selection {
    background: #ffffff00;
  }
`;

export default Promote;
