import React from 'react';
import {AdminContextProvider} from './AdminEngine';
import {pageViewGA} from '../Common/Utils/analytics';
import {Container, Grid} from '@material-ui/core';
import styled from 'styled-components';
import ComponentSelector from './ComponentSelector';
import ComponentView from './ComponentView';
import BottomButton from './BottomButton';

const ComponentsAdministrationPage = () => {
  pageViewGA();

  return <AdminContextProvider>
    <Container>
      <h1>COMPONENTS ADMINISTRATOR</h1>
      <Wrapper>
        <Grid container spacing={0}>
          <Grid item xs={12} md={3} style={{maxHeight: '65vh'}}>
            <ComponentSelector/>
          </Grid>
          <Grid item xs={12} md={9}>
            <ComponentView/>
          </Grid>
          <BottomButton/>
        </Grid>
      </Wrapper>
    </Container>
  </AdminContextProvider>;
};

const Wrapper = styled.div`
  border: 1px lightgray solid;
  border-radius: 1px;
`;

export default ComponentsAdministrationPage;
