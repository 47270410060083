import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {formatDouble, formatInteger} from '../../Common/Utils/formatter';

const StockJournalTable = ({data}) => {
  const calculateTotalEquity = data.map((row) => row.marketValue).reduce((a, b) => a + b, 0);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stock Code</TableCell>
            <TableCell>Avg Price</TableCell>
            <TableCell>No Shares</TableCell>
            <TableCell>Bought Date</TableCell>
            <TableCell>Market Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) =>
            <TableRow key={index}>
              <TableCell>{row.stockCode}</TableCell>
              <TableCell>{formatDouble(row.averagePrice)} {row.currency}</TableCell>
              <TableCell>{formatInteger(row.numberOfShares)}</TableCell>
              <TableCell>{row.boughtDate}</TableCell>
              <TableCell>{formatDouble(row.marketValue)} {row.currency}</TableCell>
            </TableRow>,
          )}
          <TableRow>
            <TableCell colSpan={4} className={'text-right'}>Total Equity</TableCell>
            <TableCell>{formatDouble(calculateTotalEquity)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

StockJournalTable.defaultProps = {
  data: [],
};

StockJournalTable.propTypes = {
  data:
    PropTypes.arrayOf(PropTypes.shape({
      stockCode: PropTypes.string.isRequired,
      averagePrice: PropTypes.number.isRequired,
      numberOfShares: PropTypes.number.isRequired,
      boughtDate: PropTypes.string.isRequired,
      marketValue: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    })),
};

export default StockJournalTable;
