import {setupWorker} from 'msw';

const handlers = [];

const worker = setupWorker();
handlers.forEach((handler) =>
  worker.use(handler),
);

const startMock = () => {
  console.warn('Mocking Backend');
  worker.start({
    onUnhandledRequest: (req) => {
      const parsed = JSON.stringify({method: req.method, url: req.url.href});
      console.warn(`Unhandled call${parsed}`);
    },
  });
};

export {startMock};
