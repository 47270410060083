import React from 'react';
import {Grid} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {formatDouble} from '../../Common/Utils/formatter';

const AccountSummary = ({data}) => {
  return <Wrapper id={'AccountSummary'}>
    <Grid container spacing={1}>
      <Grid item xs={6} sm={9} md={10}/>
      <Grid item xs={6} sm={3} md={2}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            Buying Power
          </Grid>
          <Grid item xs={6}>
            {data.lastDepositOrWithdrawalDate}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} className={'container'}>
              <Grid item xs={9} className={'amount'}>
                {formatDouble(data.amount)}
              </Grid>
              <Grid item xs={3} className={'currency'}>
                {data.currency}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Wrapper>;
};

AccountSummary.defaultProps = {};

AccountSummary.propTypes = {
  data: PropTypes.shape({
    lastDepositOrWithdrawalDate: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }),
};

const Wrapper = styled(Grid)`
  .container{
    border: 1px orange solid;
  
    .amount{
      text-align: right;
    }
    .currency{
      text-align: left;
    }
  }
`;

export default AccountSummary;
