import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import {Floating} from '../../Common/Components/Floating';
import {AnimationType, RunningAnimation} from './Behavior/behaviorDefinitions';
import {AnimationBehaviorContext} from './Behavior/AnimationBehaviorHandler';

const Draggable = ({id, x, y, z, onDragged, onDragStart, onDragEnd, dragOver, children}) => {
  const [enabled, setEnabled] = useState(true);
  const [animating, setAnimating] = useState(false);
  const animationContext = useContext(AnimationBehaviorContext);
  const animation = new RunningAnimation(AnimationType.Drag, id);

  return <Floating x={x} y={y} z={z}>
    <motion.div
      drag={enabled}
      dragConstraints={dragOver}
      dragMomentum={false}
      dragTransition={{
        delay: 0.2,
      }}
      onDragStart={async (e) => {
        if (animationContext.allowed(animation)) {
          animationContext.start(animation);
          onDragStart(e);
          setAnimating(true);
        } else if (!animating) {
          await setEnabled(false);
          await setEnabled(true);
        }
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        onDragEnd(e);
      }}
      onDragOver={(e) =>
        e.preventDefault()}
      onDragTransitionEnd={() => {
        animationContext.stop(animation);
        onDragged();
        setAnimating(false);
      }}>
      <div id={id}>{children}</div>
    </motion.div>
  </Floating>;
};

Draggable.defaultProps = {
  id: `Draggable-${new Date().getTime()}`,
  x: 50,
  y: 50,
  z: 0,
  onDragged: () => null,
  onDragStart: () => null,
  onDragEnd: () => null,
  dragOver: false,
};

Draggable.propTypes = {
  id: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  onDragged: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  dragOver: PropTypes.oneOfType([
    PropTypes.oneOf([false]),
    PropTypes.shape({current: PropTypes.instanceOf(HTMLElement)}),
  ],
  ),
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count !== 1) {
      return new Error(`\`${componentName}\` should contain one children (${count} received)`);
    }
  },
};

export default Draggable;
