import useAxios from 'axios-hooks';
import {useContext} from 'react';
import {userContext} from '../../Login/useToken';
import {emptyFunction} from '../Utils/dumbUtils';

const useRetrieve = (origin, manual = false, onRetrieved, onError = emptyFunction) => {
  const token = useContext(userContext);
  const authHeader = {
    'Authorization': 'Bearer ' + token,
  };

  const config = {
    url: origin,
    method: 'GET',
    headers: {...authHeader},
  };

  const [{data, loading, error}, refresh] = useAxios(
    config, {manual: manual, useCache: false},
  );

  const onRefresh = () => {
    refresh().then(() => {
      onRetrieved();
    }).catch(() => {
      onError();
    });
  };

  return {
    data,
    loading,
    error,
    refresh: onRefresh,
  };
};

export default useRetrieve;
