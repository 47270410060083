import {useState} from 'react';

const useFormDataSync = () => {
  const [currentData, setCurrentData] = useState({});

  const mappings = {
    'CREATE_BUSINESS_CARD': {
      type: 'type',
      componentName: 'componentName',
      name: 'name',
      urlImageLogo: 'urlImageLogo',
      profession: 'profession',
    },
    'DEPOSIT': {
      currencyCode: 'currencyCode',
      depositAmount: 'amount',
      dateDeposited: 'date',
    },
    'WITHDRAW': {
      currencyCode: 'currencyCode',
      withdrawAmount: 'amount',
      dateWithdrawn: 'date',
    },
    'BUY': {
      currencyCode: 'currencyCode',
      stockCode: 'stockCode',
      buyPrice: 'price',
      numberOfShares: 'numberOfShares',
      dateBought: 'date',
      brokerageFee: 'fees',
    },
    'SELL': {
      currencyCode: 'currencyCode',
      stockCode: 'stockCode',
      sellPrice: 'price',
      numberOfShares: 'numberOfShares',
      dateSold: 'date',
      brokerageFee: 'fees',
    },
    'DIVIDEND': {
      currencyCode: 'currencyCode',
      stockCode: 'stockCode',
      earningsPerShare: 'price',
      numberOfShares: 'numberOfShares',
      dateEarned: 'date',
      dividendFee: 'fees',
    },
    'generic': {
      currencyCode: 'currencyCode',
      stockCode: 'stockCode',
      sellPrice: 'price',
      buyPrice: 'price',
      earningsPerShare: 'price',
      price: 'price',
      numberOfShares: 'numberOfShares',
      dateSold: 'date',
      dateBought: 'date',
      dateDeposited: 'date',
      dateWithdrawn: 'date',
      dateEarned: 'date',
      date: 'date',
      brokerageFee: 'fees',
      dividendFee: 'fees',
      fees: 'fees',
      depositAmount: 'amount',
      withdrawAmount: 'amount',
      amount: 'amount',
      type: 'type',
      componentName: 'componentName',
      name: 'name',
      urlImageLogo: 'urlImageLogo',
      profession: 'profession',
    },
  };

  const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(v)]));

  const getDefault = (type) => {
    return objectMap(mappings[type], (it) => currentData[it]);
  };

  const reset = () => {
    setCurrentData({});
  };

  const handleData = (field, value) => {
    currentData[mappings.generic[field]] = value;
    setCurrentData({...currentData});
  };

  return {
    dataSync: {
      getDefault: getDefault,
      handleData: handleData,
      reset: reset,
    },
  };
};

export default useFormDataSync;
