import React, {useContext} from 'react';
import styled from 'styled-components';
import AdminContext from './AdminEngine';
import BusinessCardView from './BusinessCardView';
import CreateComponentView from './CreateComponentView';
import CreateBusinessCardView from './CreateBusinessCardView';

const ComponentView = () => {
  const {selectedComponent, creatingComponent, refreshComponents} = useContext(AdminContext);

  return <Wrapper>
    {selectedComponent ?
      <BusinessCardView retrieve={refreshComponents}/> :
      creatingComponent === 'selecting' ? <CreateComponentView/> :
        creatingComponent === 'businessCard' ? <CreateBusinessCardView/> :
          <div className={'welcomeMessage'}>Welcome, Select an item to edit it or create a new component.</div>}
  </Wrapper>;
};

const Wrapper = styled.div`
  min-height: 65vh;
  display: flex;
  justify-content: center;

  .welcomeMessage {
    color: gray;
    font-size: 15px;
    margin: auto;
  }
`;

export default ComponentView;
