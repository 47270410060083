import {env} from './env.js';

export const Endpoints = {
  basePath: () => `${env.PUBLIC_URL === '' ? '/' : env.PUBLIC_URL}`,
  chessPage: () => `${env.PUBLIC_URL}/chess`,
  desktopPage: () => `${env.PUBLIC_URL}/desktop`,
  languageCardPage: (language) => `${env.PUBLIC_URL}/languageCards/${language}`,
  paperFormPage: (name) => `${env.PUBLIC_URL}/paperForm/${name}`,

  // Auth endpoints
  logIn: () => `${env.SERVER_URL}/api/auth/signin`,

  logInPage: () => `${env.PUBLIC_URL}/login`,
  logOutPage: () => `${env.PUBLIC_URL}/logout`,

  // Stocks endpoints
  buyStockTransaction: (accountId) => `${env.SERVER_URL}/api/stocks/transactions/buy?accountId=${accountId}`,
  sellStockTransaction: (accountId) => `${env.SERVER_URL}/api/stocks/transactions/sell?accountId=${accountId}`,
  dividendTransaction: (accountId) => `${env.SERVER_URL}/api/stocks/transactions/getDividend?accountId=${accountId}`,
  lastTransaction: (accountId) => `${env.SERVER_URL}/api/stocks/transactions/last?accountId=${accountId}`,
  stockTransactions: (accountId) => `${env.SERVER_URL}/api/stocks/transactions?accountId=${accountId}`,
  stocksJournal: (accountId) => `${env.SERVER_URL}/api/stocks/transactions/journal?accountId=${accountId}`,
  monthlyProfitSummary: (year, month, accountId) => `${env.SERVER_URL}/api/stocks/transactions/profitSummary/${year}/${month}?accountId=${accountId}`,
  stockCodes: () => `${env.SERVER_URL}/api/stocks/code`,

  stockJournalPage: () => `${env.PUBLIC_URL}/stocks/journal`,
  stockTransactionsPage: () => `${env.PUBLIC_URL}/stocks/transactions`,
  stockProfitSummaryPage: () => `${env.PUBLIC_URL}/stocks/profitSummary`,

  // Accounts endpoints
  defaultAccount: () => `${env.SERVER_URL}/api/accounts/default`,
  accountSummary: (accountId) => `${env.SERVER_URL}/api/accounts/${accountId}/summary`,
  accountMovements: (types, accountId) => `${env.SERVER_URL}/api/accounts/${accountId}/movements?types=${types}`,
  depositInAccount: (accountId) => `${env.SERVER_URL}/api/accounts/${accountId}/movements/deposit`,
  withdrawFromAccount: (accountId) => `${env.SERVER_URL}/api/accounts/${accountId}/movements/withdraw`,

  // Components endpoints
  components: () => `${env.SERVER_URL}/api/components`,
  deleteComponent: (componentId) => `${env.SERVER_URL}/api/components?id=${componentId}`,
  createComponent: () => `${env.SERVER_URL}/api/components`,
};
