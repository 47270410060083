import {useEffect} from 'react';
import ReactGA from 'react-ga';

const isProd = document.location.hostname.search('rgapps.site') !== -1;
const isQa = document.location.hostname === 'localhost';
let eventElementsInjected = [];

const initializeGA = () => {
  if (isProd) {
    ReactGA.initialize('UA-56248197-2');
  }
};

// TODO: add unittests
export const useAnalytics = () => {
  useEffect(() => {
    initializeGA();

    setInterval(() => {
      document.querySelectorAll('*').forEach((element) => {
        if (!eventElementsInjected.includes(element)) {
          eventElementsInjected = [...eventElementsInjected, element];
          if (element.tagName === 'BUTTON') {
            eventElementsInjected = [...eventElementsInjected, ...element.children];
            if (!element.id && !element.value && !element.innerText && !element.title) {
              console.debug(element);
              console.error('BUTTON has no id, value, innerText nor title');
            } else {
              element.addEventListener('click', (e) => {
                if (element.innerText) {
                  buttonEventGA('click', element.innerText);
                } else if (element.id) {
                  buttonEventGA('click', element.id);
                } else if (element.value) {
                  buttonEventGA('value', element.value);
                }
              });
            }
          } else if (element.tagName === 'INPUT') {
            eventElementsInjected = [...eventElementsInjected, ...element.children];
            if (!element.id && !element.name && !element.labels && !element.labels[0] && !element.labels[0].textContent) {
              console.error('INPUT has no id nor name nor label');
            }
            {
              element.addEventListener('change', (e) => {
                if (element.labels && element.labels[0] && element.labels[0].textContent) {
                  inputEventGA('change', element.labels[0].textContent);
                } else if (element.name) {
                  inputEventGA('change', element.name);
                } else if (element.id) {
                  inputEventGA('change', element.id);
                }
              });
            }
          } else if (!element.childElementCount) {
            element.addEventListener('click', (e) => {
              if (!element.childElementCount) {
                unknowntEventGA('click', element.outerHTML);
              }
            });
          }
        }
      });
    }, 100);
  }, []);
};

export const pageViewGA = () => {
  useEffect(() => {
    const pageUrl = window.location.pathname + window.location.search;
    if (isProd) {
      ReactGA.pageview(pageUrl);
    } else if (!isQa) {
      console.debug('pageViewGA ' + pageUrl);
    }
  }, []);
};

export const animationEventGA = (animation, label) => {
  if (isProd) {
    ReactGA.event({
      category: 'Animation',
      action: animation,
      label,
    });
  } else if (!isQa) {
    console.debug(`animationEventGA (${animation},${label})`);
  }
};

export const buttonEventGA = (event, label) => {
  if (isProd) {
    ReactGA.event({
      category: 'Button',
      action: event,
      label,
    });
  } else if (!isQa) {
    console.debug(`buttonEventGA (${event},${label})`);
  }
};

export const inputEventGA = (event, label) => {
  if (isProd) {
    ReactGA.event({
      category: 'Input',
      action: event,
      label,
    });
  } else if (!isQa) {
    console.debug(`inputEventGA (${event},${label})`);
  }
};

export const unknowntEventGA = (event, label) => {
  if (isProd) {
    ReactGA.event({
      category: 'Unknown',
      action: event,
      label,
    });
  } else if (!isQa) {
    console.debug(`unknowntEventGA (${event},${label})`);
  }
};
