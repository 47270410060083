import React, {useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';
import {AnimationBehaviorContext} from './Behavior/AnimationBehaviorHandler';
import {AnimationType, notPropagateEvent, RunningAnimation} from './Behavior/behaviorDefinitions';

const Flippable = ({id, startFlipped, externalTrigger, onFlipped, onFlipStart, children}) => {
  const [rotation, setRotation] = useState(startFlipped ? 180 : 0);
  const [isFlipped, setFlip] = useState(startFlipped);
  const [hasExternalTrigger, withExternalTrigger] = useState(false);
  const animationContext = useContext(AnimationBehaviorContext);
  const animation = new RunningAnimation(AnimationType.Flip, id);

  const onClick = useCallback((e) => {
    notPropagateEvent(e);
    if (animationContext.allowed(animation)) {
      setRotation(90);
      animationContext.start(animation);
      onFlipStart(isFlipped);
    }
  }, [animation, animationContext, onFlipStart, isFlipped]);

  useEffect(() => {
    if (externalTrigger !== null) {
      withExternalTrigger(true);
      if (externalTrigger.current !== null) externalTrigger.current.onclick = onClick;
    }
  }, [externalTrigger, onClick]);

  const onComplete = () => {
    if (rotation === 90) {
      setRotation(isFlipped ? 0 : 180);
      setFlip(!isFlipped);
    } else {
      onFlipped(isFlipped);
      animationContext.stop(animation);
    }
  };

  return <>
    {isFlipped ?
      <motion.div
        initial={{rotateY: rotation - 180}}
        animate={{rotateY: rotation - 180}}
        transition={{bounceStiffness: 100}}
        onClick={(e) => {
          if (!hasExternalTrigger) onClick(e);
        }}
        onAnimationComplete={onComplete}>
        {children[1] || ''}
      </motion.div> :
      <motion.div
        initial={{rotateY: rotation}}
        animate={{rotateY: rotation}}
        transition={{bounceStiffness: 100}}
        onClick={(e) => {
          if (!hasExternalTrigger) onClick(e);
        }}
        onAnimationComplete={onComplete}>
        {children[0]}
      </motion.div>
    }
  </>;
};

Flippable.defaultProps = {
  onFlipped: () => null,
  onFlipStart: () => null,
  startFlipped: false,
  externalTrigger: null,
};

Flippable.propTypes = {
  id: PropTypes.string.isRequired,
  onFlipped: PropTypes.func,
  onFlipStart: PropTypes.func,
  startFlipped: PropTypes.bool,
  externalTrigger: PropTypes.shape({current: PropTypes.instanceOf(HTMLElement)}),
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count === 0 || count > 2) {
      return new Error(`\`${componentName}\` should contain at least one and maximum two children (${count} received)`);
    }
  },
};

export default Flippable;
