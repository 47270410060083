export const Ids = {
  Desk: 'desk',
  PersonalCard: 'personal-card',
  Chronometer: 'chronometer',
  Calculator: 'calculator',
  GameCard: 'game-card',
  Chess: 'chess',
  notebook: (name) => `Notebook ${name}`,
  paperForm: (name) => `paperForm ${name}`,
  languageCard: (detail) => `languageCard ${detail}`,
};
