import * as ChessJS from 'chess.js/chess';
import {BehaviorSubject} from 'rxjs';

const Chess = typeof ChessJS === 'function' ? ChessJS : ChessJS.Chess;
const chess = new Chess();

export const gameBoard = new BehaviorSubject();

export const initGame = () => {
  updateGame();
};

export const handleMove = (from, to) => {
  const promotions = chess.moves({verbose: true}).filter((move) => move.promotion);

  if (promotions.some((promotion) => `${promotion.from}:${promotion.to}` === `${from}:${to}`)) {
    const pendingPromotion = {from, to, color: promotions[0].color};
    updateGame(pendingPromotion);
  }

  const pendingPromotion = gameBoard.getValue().pendingPromotion;

  if (!pendingPromotion) {
    move(from, to);
  }
};

export const move = (from, to, promotion) => {
  const tempMove = {from, to};
  if (promotion) {
    tempMove.promotion = promotion;
  }
  const legalMove = chess.move(tempMove);
  if (legalMove) {
    updateGame();
  }
};

export const resetGame = () => {
  chess.reset();
  updateGame();
};

const updateGame = (pendingPromotion) => {
  const isGameOver = chess.game_over();

  const newGame = {
    board: chess.board(),
    pendingPromotion,
    isGameOver,
    result: isGameOver ? gameResult() : null,
  };

  gameBoard.next(newGame);
};

const gameResult = () => {
  if (chess.in_checkmate()) {
    const winner = chess.turn() === 'w' ? 'BLACK' : 'WHITE';
    return `The Winner is the ${winner} player`;
  } else if (chess.in_draw()) {
    if (chess.in_stalemate()) {
      return 'STALEMATE';
    } else if (chess.in_threefold_repetition()) {
      return 'Game DRAW due to move repetition';
    } else if (chess.insufficient_material()) {
      return 'Game DRAW due to insufficient material';
    }
  }
};
