import React from 'react';
import styled from 'styled-components';
import Desk from './Desk';
import Scrollable from '../Animations/Scrollable';
import {Floating} from '../../Common/Components/Floating';
import {Ids} from '../ids';
import {pageViewGA} from '../../Common/Utils/analytics';

const DeskPage = () => {
  pageViewGA();

  return (
    <Scrollable contentWidth={2500} contentHeight={1700} id={Ids.Desk}>
      <Floor>
        <Desk/>
      </Floor>
    </Scrollable>
  );
};

const Floor = styled(Floating)`
    width: 2500px;
    height: 1700px;
    background: url('resources/floor.jpg');
`;

export default DeskPage;
