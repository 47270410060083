import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Floating} from '../../Common/Components/Floating';
import Pageable from './Pageable';

const PageableGroup = ({id, pageWidth, pageCount, startingPage, onPaged, externalFrontTrigger, externalBackTrigger, children}) => {
  const [currentPage, setPage] = useState(startingPage);
  const [paging, setPaging] = useState(null);

  const startsPaged = (page) => page < startingPage;

  const onPagedHandler = (rightToLeft) => {
    const newPage = rightToLeft ? currentPage + 1 : currentPage - 1;
    setPage(newPage);
    setPaging(null);
    onPaged(newPage);
  };

  const onPagingStartHandler = (page) => {
    setPaging(page);
  };

  const zIndex = (page) => {
    return paging === page ? pageCount :
      currentPage <= page ? pageCount - 1 - page : page;
  };

  const getTrigger = (page) => {
    return page === currentPage ? externalFrontTrigger : page + 1 === currentPage ? externalBackTrigger : null;
  };

  const getPageWidth = (page) => Array.isArray(pageWidth) ? pageWidth[page] : pageWidth;

  return <>
    {Array.from(Array(pageCount).keys()).map((page) =>
      <Floating z={zIndex(page)} key={page}>
        <Pageable id={`${id} - ${page}`}
          pageWidth={getPageWidth(page)}
          startPaged={startsPaged(page)}
          externalTrigger={getTrigger(page)}
          onPaged={onPagedHandler}
          onPagingStart={() => onPagingStartHandler(page)}>
          {React.Children.toArray(children)[page].props.children}
        </Pageable>
      </Floating>,
    )}
  </>;
};

PageableGroup.defaultProps = {
  onPaged: () => null,
  externalFrontTrigger: null,
  externalBackTrigger: null,
  delay: 0,
  startPaged: false,
  startingPage: 0,
};

PageableGroup.propTypes = {
  id: PropTypes.string.isRequired,
  onPaged: PropTypes.func,
  startPaged: PropTypes.bool,
  externalFrontTrigger: PropTypes.shape({current: PropTypes.instanceOf(HTMLElement)}),
  externalBackTrigger: PropTypes.shape({current: PropTypes.instanceOf(HTMLElement)}),
  startingPage: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  pageWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const pageCountProp = props.pageCount;
    const count = React.Children.count(prop);
    if (count !== pageCountProp) {
      return new Error(`\`${componentName}\` should contain ${pageCountProp} children (${count} received)`);
    }
    if (React.Children.toArray(prop).some((c) => c.type.toString() !== 'Symbol(react.fragment)')) {
      return new Error(`\`${componentName}\` should contain ${pageCountProp} Fragments with two children each`);
    }
    if (React.Children.toArray(prop).some((c) => React.Children.count(c.props.children) !== 2)) {
      return new Error(`each children of \`${componentName}\` should contain exactly 2 children (front and back)`);
    }
  },
  checkStartingPage: (props, propName, componentName) => {
    if (props.startingPage > props.pageCount) {
      return new Error(`\`${componentName}\`'s \`startingPage\` should be lower than the \`pageCount\` ` +
        `(trying to start in ${props.startingPage} with ${props.pageCount} pages)`);
    }
  },
  checkPageWidthArray: (props, propName, componentName) => {
    const pageWidth = props.pageWidth;
    if (Array.isArray(pageWidth) && pageWidth.length !== props.pageCount) {
      return new Error(`\`${componentName}\`'s \`pageWidth\` should be a number or an Array of size \`pageCount\` (received ${pageWidth})`);
    }
  },
};

export default PageableGroup;
