import React, {useState} from 'react';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';

let interval = null;
const Chronometer = () => {
  const [time, setTime] = useState({hms: '00:00:00', milis: '000', diff: 0});

  let startTime = null;
  const [running, setRunning] = useState(false);
  const updateTime = () => {
    const diff = new Date() - startTime;
    const diffTime = new Date(new Date() - startTime);
    const milis = diffTime.getMilliseconds();
    setTime({
      hms: diffTime.toUTCString().split(' ')[4],
      milis: milis > 99 ? milis : milis > 9 ? `0${milis}` : `00${milis}`,
      diff: diff,
    });
  };

  const start = () => {
    if (!running) {
      startTime = new Date() - time.diff;
      interval = setInterval(updateTime, 1);
      setRunning(true);
    }
  };

  const restart = () => {
    setTime({hms: '00:00:00', milis: '000', diff: 0});
    if (running) {
      clearInterval(interval);
      startTime = new Date();
      interval = setInterval(updateTime, 1);
    }
  };

  const stop = () => {
    clearInterval(interval);
    setRunning(false);
  };
  return <Wrapper>
    <Display x={50} y={43}>
      <div>{time.hms}</div>
      <div className={'milis'}>{time.milis}</div>
    </Display>
    <Button id={'buttonRestart'} action={'restart'} onClick={restart}>Restart</Button>
    <Button id={'buttonStop'} action={'stop'} onClick={stop}>Stop</Button>
    <Button id={'buttonStart'} action={'start'} onClick={start}>Start</Button>
  </Wrapper>;
};
const Button = styled.button`
  margin-right: 8.5px;
  margin-left: 8.5px;
  width: 50px;
  height: 23px;
  background: ${({action}) => (action === 'stop') ? '#e53935' : (action === 'start') ? '#4aae71' : '#f1c40f'};
  cursor: pointer;
  border: none;
  padding: 0;
  color: #000;
  transform: translate(60px, 115px);
`;

const Display = styled(Floating)`
  display: flex;
  width: 240px;
  height: 75px;
  background: #a3b2a3;
  border: 2px #6543ce solid;
  align-items: center;
  justify-content: center;
  font-size: 1em;´
  text-align: center;
  font-family: Stopwatch W00 Regular;

  .milis {
    font-size: 0.5em;
    padding-top: 17px;
    width: 36px;
  }
`;

const Wrapper = styled(Floating)`
  display: flex;
  width: 330px;
  height: 150px;
  font-size: 50px;
  background-color: #212121;
  border-radius: 10px;
  border: 5px #6543ce solid;
`;
export default Chronometer;
