import useAxios from 'axios-hooks';
import {emptyFunction} from '../Utils/dumbUtils';
import {useContext} from 'react';
import {userContext} from '../../Login/useToken';

const useDelete = (location, onDeleted, onError = emptyFunction) => {
  const token = useContext(userContext);
  const authHeader = {
    'Authorization': 'Bearer ' + token,
  };

  const [{data, loading: deleting, error}, doDelete] = useAxios({
    url: location,
    method: 'DELETE',
    headers: {...authHeader},
  }, {manual: true, useCache: false});

  const callDelete = () => {
    // TODO: validate currentValues (type and formatting) here before calling the backend
    doDelete().then(() => {
      onDeleted();
    }).catch(() => {
      onError();
    });
  };

  return {
    data,
    deleting,
    error,
    delete: callDelete,
  };
};

export default useDelete;
