import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import DeskPage from './Desktop/Desk/DeskPage';
import {useAnalytics} from './Common/Utils/analytics';
import PaperFormPage from './Desktop/PaperForm/PaperFormPage';
import AnimationBehaviorHandler from './Desktop/Animations/Behavior/AnimationBehaviorHandler';
import {AnimationsBehaviorType} from './Desktop/Animations/Behavior/behaviorDefinitions';
import LanguageCardsPage from './Desktop/LanguageCards/LanguageCardsPage';
import StockJournalPage from './Stocks/Journal/StockJournalPage';
import MonthlyProfitSummaryPage from './Stocks/Profit Summary/MonthlyProfitSummaryPage';
import ChessPage from './Desktop/Chess/ChessPage';
import StockTransactionsPage from './Stocks/Transactions/StockTransactionsPage';
import ComponentsAdministrationPage from './ComponentsAdmin/ComponentsAdministrationPage';
import LoginPage from './Login/LoginPage';
import useToken from './Login/useToken';
import {env} from './env';

const App = () => {
  useAnalytics();

  const {authenticationRequired, token, setToken, userContext} = useToken([
    '/desktop',
    '/chess',
    '/languageCards/Spanish',
    '/languageCards/English',
    '/logout']);

  return <>
    {authenticationRequired ? '401' : <BrowserRouter basename={env.PUBLIC_URL}>
      <Switch>
        <userContext.Provider value={token}>
          <AnimationBehaviorHandler behaviorType={AnimationsBehaviorType.One}>
            <Route exact path={'/desktop'} component={DeskPage}/>
            <Route path={'/login'}>
              <LoginPage setToken={setToken}/>
            </Route>
            <Route path={'/logout'}>Logged Out</Route>
            <Route path={'/desktop/admin'} component={ComponentsAdministrationPage}/>
            <Route path={'/paperForm/:name'} component={PaperFormPage}/>
            <Route path={'/languageCards/:url'} component={LanguageCardsPage}/>
            <Route path={'/chess'} component={ChessPage}/>
            <Route path={'/stocks/transactions'} component={StockTransactionsPage}/>
            <Route path={'/stocks/journal'} component={StockJournalPage}/>
            <Route path={'/stocks/profitSummary'} component={MonthlyProfitSummaryPage}/>
            <Route exact path="/">
              <Redirect to="/desktop"/>
            </Route>
          </AnimationBehaviorHandler>
        </userContext.Provider>
      </Switch>
    </BrowserRouter>}
  </>;
};

export default App;
