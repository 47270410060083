import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import {CustomPropTypes} from '../../Common/Utils/customPropTypes';
import {PageType} from './model';
import {notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';

const Sheet = ({content, back}) => {
  return <Wrapper back={back}>
    <Floating>
      {content.type === PageType.EXTERNAL ?
        <Content onClick={(e) => {
          notPropagateEvent(e);
          window.location.assign(content.source);
        }}>
          <iframe src={content.source}/>
        </Content> :
        content.content}
    </Floating>
  </Wrapper>;
};

Sheet.defaultProps = {
  back: false,
};

Sheet.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.oneOf([PageType.DIRECT, PageType.EXTERNAL]).isRequired,
    content: CustomPropTypes.requiredIf('type', PageType.DIRECT),
    source: CustomPropTypes.requiredIf('type', PageType.EXTERNAL),
  },
  ).isRequired,
  back: PropTypes.bool,
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: ${({back}) => back ? '8px 0 0 8px' : '0 8px 8px 0'};
    background: url('resources/paper.jpg');
    background-size: cover;
    background-color: white;
    text-align: center;
`;

export const Content = styled(Floating)`
    width: 300px;
    height: 380px;
    cursor: pointer;
    
    :hover{
      border: 1px solid blue;
      background-color: #FAFAFF;
    }

    iframe{
      border:none;
      pointer-events: none;
    }
`;

export default Sheet;
