import React, {useRef, useState} from 'react';
import Draggable from '../Animations/Draggable';
import PersonalCard from '../PersonalCard/PersonalCard';
import Notebook from '../Notebook/Notebook';
import LanguageCardsGame from '../LanguageCards/LanguageCardsGame';
import Chronometer from '../Chronometer/Chronometer';
import Calculator from '../Calculator/Calculator';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';
import {Ids} from '../ids';
import PaperForm from '../PaperForm/PaperForm';
import Chess from '../Chess/Chess';
import {PageType} from '../Notebook/model';
import {Endpoints} from '../../endpoints';
import {defaultAccount} from '../../Stocks/__tests/modelData';

const Desk = () => {
  const [topElement, setTop] = useState('');
  const ref = useRef(null);

  // TODO: this will be replaced by server retrieved data
  const personalData = {
    name: 'Wilson René Guevara Arévalo',
    mote: 'wilsonr990',
    profession: 'Software Developer',
  };

  // TODO: this will be replaced by server retrieved data
  const stocksNotebook = {
    name: 'Stocks',
    pages: [
      {type: PageType.DIRECT, content: 'My Stocks'},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.EXTERNAL, source: Endpoints.stockTransactionsPage()},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''},
      {type: PageType.DIRECT, content: ''}],
  };

  const englishCards = {
    name: 'LanguageCardEnglish',
    language: 'English',
    cards: [{name: 'dummy card'}],
  };

  const spanishCards = {
    name: 'LanguageCardSpanish',
    language: 'Spanish',
    cards: [{name: 'dummy card'}],
  };

  // TODO: this will be replaced by server retrieved data
  const formContent = {
    name: 'StocksForm',
    fields: [
      {text: 'Bought In Date', name: 'dateBought'},
      {text: 'Stock', name: 'stock'},
      {text: 'Cost Per Share', name: 'costPerShare'},
      {text: 'Amount', name: 'amount'},
    ],
    destination: Endpoints.stocksJournal(defaultAccount.id),
  };

  const isHorizontal = () => document.documentElement.clientWidth >= document.documentElement.clientHeight;

  return <Wrapper ref={ref}>
    <Draggable
      dragOver={ref} style={{width: '100%', height: '100%'}}
      x={isHorizontal() ? 40 : 50}
      y={isHorizontal() ? 44 : 30}
      z={topElement === Ids.PersonalCard ? 1 : 0}
      onDragStart={() => setTop(Ids.PersonalCard)}
      id={Ids.PersonalCard}>
      <PersonalCard data={personalData}/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 60 : 50}
      y={isHorizontal() ? 50 : 60}
      z={topElement === Ids.notebook(stocksNotebook.name) ? 1 : 0}
      onDragStart={() => setTop(Ids.notebook(stocksNotebook.name))}
      id={Ids.notebook(stocksNotebook.name)}>
      <Notebook content={stocksNotebook}/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 60 : 50}
      y={isHorizontal() ? 72 : 90}
      z={topElement === Ids.paperForm(formContent.name) ? 1 : 0}
      onDragStart={() => setTop(Ids.paperForm(formContent.name))}
      id={Ids.paperForm(formContent.name)}>
      <PaperForm content={formContent}/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 60 : 50}
      y={isHorizontal() ? 25 : 20}
      z={topElement === Ids.Chronometer ? 1 : 0}
      onDragStart={() => setTop(Ids.Chronometer)}
      id={Ids.Chronometer}>
      <Chronometer/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 40 : 25}
      y={!isHorizontal() ? 50 : 25}
      z={topElement === Ids.languageCard('English') ? 1 : 0}
      onDragStart={() => setTop(Ids.languageCard('English'))}
      id={Ids.languageCard('English')}>
      <LanguageCardsGame
        content={englishCards}
        titleGame={'EnglishOnCards'}
        slogan={'Learn English has never been so much fun'}/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 50 : 25}
      y={!isHorizontal() ? 50 : 10}
      z={topElement === Ids.languageCard('Spanish') ? 1 : 0}
      onDragStart={() => setTop(Ids.languageCard('Spanish'))}
      id={Ids.languageCard('Spanish')}>
      <LanguageCardsGame
        content={spanishCards}
        titleGame={'SpanishOnCards'}
        slogan={'Aprender Español nunca fué tan divertido'}/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 40 : 50}
      y={!isHorizontal() ? 72 : 66}
      z={topElement === Ids.Calculator ? 1 : 0}
      onDragStart={() => setTop(Ids.Calculator)}
      id={Ids.Calculator}>
      <Calculator/>
    </Draggable>
    <Draggable
      dragOver={ref}
      x={isHorizontal() ? 15 : 50}
      y={isHorizontal() ? 44 : 30}
      id={Ids.Chess}
      z={topElement === Ids.Chess ? 1 : 0}
      onDragStart={() => setTop(Ids.Chess)}>
      <Chess/>
    </Draggable>
  </Wrapper>;
};

const Wrapper = styled(Floating)`
    width: 2400px;
    height: 1600px;
    background: url('resources/desk.jpg');
    background-color: #4e371f;
    border-radius: 20px;
`;

export default Desk;
