import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import StockJournalTable from './StockJournalTable';
import {Grid} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import PropTypes from 'prop-types';

const StockJournal = ({accountId}) => {
  pageViewGA();
  const {
    data: stockJournalData,
    loading: loadingJournal,
    error: getJournalFailed,
  } = useRetrieve(Endpoints.stocksJournal(accountId));

  return <>
    <Grid container spacing={1}>
      <Grid item xs={12} md={10}/>
      <Grid item xs={12}>
        {stockJournalData && <StockJournalTable data={stockJournalData}/>}
      </Grid>
    </Grid>
    {(loadingJournal) && <Progress/>}
    {getJournalFailed && <ErrorHandler error={getJournalFailed}/>}
  </>;
};

StockJournal.defaultProps = {};

StockJournal.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default StockJournal;
