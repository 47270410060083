import {NoStatusHandlerConfigured} from './exceptions';

export const AnimationsBehaviorType = {
  // 'allow all animations to be concurrent. Don't save running apps'},
  NoHandler: 'NoHandler',
  // 'allow all animations to be concurrent'},
  NoAnimations: 'NoAnimations',
  // 'allow all animations to be concurrent'},
  AllowAll: 'AllowAll',
  // 'allow one animation at a time'},
  One: 'One',
  // 'don\'t animate while dragging. Other animations can be concurrent'},
  IndependentDrag: 'IndependentDrag',
};

export const AnimationType = {
  PagingGroup: 'PagingGroup',
  Paging: 'Paging',
  Flip: 'Flip',
  Scroll: 'Scroll',
  Drag: 'Drag',
};

export const defaultAnimationBehavior = {
  behaviorType: AnimationsBehaviorType.NoHandler,
  allowed: function(animation) {
    if (this.behaviorType === AnimationsBehaviorType.NoHandler) return true;
    return this.stateHandler.checkAllowed(animation).allowed;
  },
  start: function(animation) {
    if (this.behaviorType === AnimationsBehaviorType.NoHandler) return;
    this.stateHandler.setStart(animation);
  },
  stop: function(animation) {
    if (this.behaviorType === AnimationsBehaviorType.NoHandler) return;
    this.stateHandler.setStop(animation);
  },
  runningAny: function(animationType) {
    if (this.behaviorType === AnimationsBehaviorType.NoHandler) return;
    return this.stateHandler.runningAny(animationType);
  },
  enable: function(flag) {
    return this.stateHandler.enable(flag);
  },
  stateHandler: {
    checkAllowed: () => {
      throw new NoStatusHandlerConfigured();
    },
    setStart: () => {
      throw new NoStatusHandlerConfigured();
    },
    setStop: () => {
      throw new NoStatusHandlerConfigured();
    },
    runningAny() {
      throw new NoStatusHandlerConfigured();
    },
    enable() {
      throw new NoStatusHandlerConfigured();
    },
  },
};

export class RunningAnimation {
  constructor(animationType, elementId) {
    this.elementId = elementId;
    this.animationType = animationType;
  }

  toString() {
    return `${this.animationType} - ${this.elementId}`;
  }

  equals(a) {
    return a.animationType === this.animationType && a.elementId === this.elementId;
  }
}

export function notPropagateEvent(e) {
  e.preventDefault();
  e.cancelBubble = true;
  e.stopPropagation();
}
