import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import {formatDouble, formatInteger} from '../../Common/Utils/formatter';
import {Actions} from './ActionsMenu';

const StockTransactionTable = ({transactionsData, movementsData}) => {
  return <CustomTableContainer>
    <Table stickyHeader={true}>
      <TableHead>
        <TableRow>
          <Hidden xsDown><TableCell>Date</TableCell></Hidden>
          <TableCell>Stock Code</TableCell>
          <TableCell>Shares</TableCell>
          <Hidden xsDown><TableCell>Per Stock</TableCell></Hidden>
          <Hidden xsDown><TableCell>Fees</TableCell></Hidden>
          <TableCell>Adjusted Amount</TableCell>
          <TableCell>Total Movement</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactionsData.concat(movementsData)
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((row, index) => {
            return row.transactionType ?
              <TableRow key={index}>
                <Hidden xsDown><TableCell>{row.date}</TableCell></Hidden>
                <TableCell>{row.stockCode}</TableCell>
                <TableCell>{formatInteger(row.numberOfShares)}</TableCell>
                <Hidden xsDown><TableCell>{formatDouble(row.price)} {row.currency}</TableCell></Hidden>
                <Hidden xsDown><TableCell>{formatDouble(row.fee)} {row.currency}</TableCell></Hidden>
                <TableCell>{formatDouble(row.adjustedPrice)} {row.currency}</TableCell>
                <ColoredTableCell
                  colored={Actions.getColor(row.transactionType)}>{formatDouble(row.totalMovement)} {row.currency}</ColoredTableCell>
              </TableRow> :
              <TableRow key={index}>
                <Hidden xsDown><TableCell>{row.date}</TableCell></Hidden>
                <TableCell>--</TableCell>
                <TableCell>--</TableCell>
                <Hidden xsDown><TableCell>--</TableCell></Hidden>
                <Hidden xsDown><TableCell>--</TableCell></Hidden>
                <TableCell>--</TableCell>
                <ColoredTableCell
                  colored={Actions.getColor(row.movementType)}>{formatDouble(row.amount)} {row.currency}</ColoredTableCell>
              </TableRow>;
          })}
      </TableBody>
    </Table>
  </CustomTableContainer>;
};

StockTransactionTable.defaultProps = {
  data: [],
};

StockTransactionTable.propTypes = {
  transactionsData:
    PropTypes.arrayOf(
      PropTypes.shape({
        transactionType: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        stockCode: PropTypes.string.isRequired,
        numberOfShares: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        fee: PropTypes.number.isRequired,
        totalMovement: PropTypes.number.isRequired,
        adjustedPrice: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      })),
  movementsData:
    PropTypes.arrayOf(
      PropTypes.shape({
        movementType: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
      })),
};

export const CustomTableContainer = withStyles(() => ({
  root: () => ({
    overflowX: 'hidden',
  }),
}))(TableContainer);

export const ColoredTableCell = withStyles((theme) => ({
  root: ({colored}) => ({
    color: theme.palette.getContrastText(colored[400]),
    backgroundColor: colored[400],
  }),
}))(TableCell);

export default StockTransactionTable;
