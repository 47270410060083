import React, {useContext} from 'react';
import styled from 'styled-components';
import {ColoredButton} from '../Common/Components/styledComponents';
import {grey, lightGreen, lime, red, teal} from '@material-ui/core/colors';
import AdminContext from './AdminEngine';
import {ButtonGroup} from '@material-ui/core';

const BottomButton = () => {
  const {isCreating, creatingComponent, unselectComponent, verified, postForm} = useContext(AdminContext);

  return <Wrapper>
    {!creatingComponent ? <CreateComponentButton
      colored={lightGreen}
      onClick={() => {
        isCreating('selecting');
        unselectComponent();
      }}
    >
      CREATE COMPONENT
    </CreateComponentButton> : creatingComponent === 'selecting' ? <CancelCreating
      colored={red}
      onClick={() => {
        isCreating(false);
        unselectComponent();
      }}
    >
      CANCEL
    </CancelCreating> : <DiscardSaveButtons>
      <Button colored={teal} onClick={() => isCreating(false)}>Discard</Button>
      <Button colored={verified ? lime : grey} disabled={verified ? false : true} onClick={postForm}>Save</Button>
    </DiscardSaveButtons>}
  </Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
`;

const CancelCreating = styled(ColoredButton)`
  width: 100%;
  border-radius: 0;
`;

const CreateComponentButton = styled(ColoredButton)`
  width: 100%;
  border-radius: 0;
`;

const Button = styled(ColoredButton)`
  width: 50%;
  border-radius: 0;
`;

const DiscardSaveButtons = styled(ButtonGroup)`
  width: 100%;
`;

export default BottomButton;
