import React, {useEffect, useState} from 'react';
import Square from './Square';
import Piece from './Piece';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {gameBoard} from './Game';
import Promote from './Promote';

const BoardSquare = ({promoting, interactive, piece, black, position}) => {
  const [promotion, setPromotion] = useState(promoting || null);
  useEffect(() => {
    const subscribe = gameBoard.subscribe(({pendingPromotion}) =>
      pendingPromotion && pendingPromotion.to === position ?
        setPromotion(pendingPromotion) :
        setPromotion(null),
    );
    return () => subscribe.unsubscribe();
  });
  return <BSquare>
    <Square location={position} black={black} interactive={interactive}>
      {promotion || promoting ? <Promote promotion={promotion}/> : interactive && piece &&
        <Piece position={position} piece={piece}/>}
    </Square>
  </BSquare>;
};

BoardSquare.propTypes = {
  position: PropTypes.string,
  interactive: PropTypes.bool,
  promoting: PropTypes.object,
  piece: PropTypes.object,
  black: PropTypes.bool,
};

const BSquare = styled.div`
  width: 100%;
  height: 100%;
`;

export default BoardSquare;
