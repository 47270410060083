import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';

const FrontSide = ({data}) => {
  return <Wrapper>
    <Floating x={50} y={40}>
      <Logo name={data.name} mote={data.mote}/>
    </Floating>
    <Floating x={50} y={80}>
      {data.profession}
    </Floating>
  </Wrapper>;
};

FrontSide.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    mote: PropTypes.string,
    profession: PropTypes.string,
  }).isRequired,
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: url('resources/whiteCard.jpg');
    background-size: cover;
    background-color: white;
    border-radius: inherit;
`;

export default FrontSide;
