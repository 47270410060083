import React from 'react';
import {Floating} from '../../Common/Components/Floating';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FrontSide = ({front, language}) => {
  const Description = (language === 'English') ? 'Description' : 'Descripción';
  const Example = (language === 'English') ? 'Example' : 'Ejemplo';
  return <Wrapper>
    <Floating x={50} y={15}>
      <p><b>{front.wordType}</b></p>
    </Floating>
    <PropText x={50} y={35}>
      <p><b>{Description}:</b><br/> {front.description}</p>
    </PropText>
    <PropText x={50} y={68}>
      <p><b>{Example}:</b><br/> {front.example}</p>
    </PropText>
  </Wrapper>;
};

FrontSide.propTypes = {
  front: PropTypes.shape({
    wordType: PropTypes.string,
    description: PropTypes.string,
    example: PropTypes.string,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

const PropText = styled(Floating)`
  width: 300px;
`;

const Wrapper = styled(Floating)`
  width: 340px;
  height: 200px;
  font-family: alibaba-sans;
  font-size: 50px;
  background: #e1e1e1;
  border: 5px #283a4e solid;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 0;
`;

export default FrontSide;
