import React from 'react';
import PropTypes from 'prop-types';
import GeneralError from './GeneralError';

const LockingError = ({error, message}) => {
  return (
    <GeneralError title={'Network Error'}
      error={error}
      message={message}/>
  );
};

LockingError.defaultProps = {
  error: 'Unknown',
  message: '',
};

LockingError.propTypes = {
  error: PropTypes.string,
  message: PropTypes.string,
};

export default LockingError;
