import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import styled from 'styled-components';
import Chess from './Chess';
import {notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {Endpoints} from '../../endpoints';

const ChessPage = () => {
  pageViewGA();
  return <Wrapper onClick={(e) => {
    notPropagateEvent(e);
    window.location.assign(Endpoints.desktopPage());
  }}>
    <Zoom x={40}>
      <Chess interactive={true}/>
    </Zoom>
  </Wrapper>;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('resources/desk.jpg');
  background-color: #4e371f;
`;

const Zoom = styled.div`
  zoom: 1.2;
`;

export default ChessPage;
