import React, {useState} from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import MonthlyProfitSummaryTable from './MonthlyProfitSummaryTable';
import {Button, Grid, TextField} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const MonthlyProfitSummary = ({accountId}) => {
  pageViewGA();

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const [filter, setFilter] = useState({year: (new Date()).getFullYear(), month: months[(new Date()).getMonth()]});

  const {
    data: profitSummaryData,
    loading: loadingSummary,
    error: getSummaryFailed,
  } = useRetrieve(Endpoints.monthlyProfitSummary(filter.year, months.indexOf(filter.month) + 1, accountId));

  const onFilterChange = (key, value) => {
    filter[key] = value;
    setFilter({...filter});
  };

  return <>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <Button variant="contained" color="primary"
          onClick={() => window.location.assign(Endpoints.stockTransactionsPage())}>
          Go to Transactions</Button>
      </Grid>
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2}/>
          <Grid item xs={4}>
            <TextField
              label="Year"
              type="number"
              InputLabelProps={{shrink: true}}
              value={filter.year}
              onChange={(e) => {
                onFilterChange('year', e.target.value);
              }}/>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id={'autocomplete-month'}
              freeSolo
              autoSelect
              options={months}
              value={filter.month}
              onInputChange={(e, newValue) => {
                onFilterChange('month', newValue);
              }}
              renderInput={(params) =>
                <TextField {...params} label={'Month'}/>}
            />
          </Grid>
          <Grid item xs={2}/>
        </Grid>
      </Grid>
      <Grid item xs={false} md={2}/>
      <Grid item xs={12}>
        {profitSummaryData && <MonthlyProfitSummaryTable data={profitSummaryData}/>}
      </Grid>
    </Grid>
    {(loadingSummary) && <Progress/>}
    {getSummaryFailed && <ErrorHandler error={getSummaryFailed}/>}
  </>;
};

MonthlyProfitSummary.defaultProps = {};

MonthlyProfitSummary.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default MonthlyProfitSummary;
