import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Floating} from '../../Common/Components/Floating';
import {AnimationType, notPropagateEvent} from '../Animations/Behavior/behaviorDefinitions';
import {AnimationBehaviorContext} from '../Animations/Behavior/AnimationBehaviorHandler';
import Draggable from '../Animations/Draggable';
import GameCard from './GameCard';
import {Ids} from '../ids';
import {Endpoints} from '../../endpoints';

const LanguageCardsGame = ({interactive, titleGame, slogan, content}) => {
  const [cardOrder, setCardOrder] = useState(Array.from(Array(content.cards.length).keys()).reverse());
  const setCurrentCard = (index) => {
    setCardOrder([...cardOrder.filter((i) => i !== index), index]);
  };
  const zIndex = (index) => {
    return cardOrder.findIndex((i) => i === index);
  };
  const animationContext = useContext(AnimationBehaviorContext);
  const Finish = (content.language === 'English') ? 'Congratulations' : 'Felicidades';

  const onWrapperClicked = (e) => {
    notPropagateEvent(e);
    if (!interactive && !animationContext.runningAny(AnimationType.Drag)) {
      window.location.assign(Endpoints.languageCardPage(content.language));
    }
  };

  return <Wrapper onClick={onWrapperClicked}>
    <CoverStyle visible={interactive}>
      <Title>{titleGame}</Title>
      <CardCover colorCard={'blue'} x={-25} y={-24} angle={150}/>
      <CardCover colorCard={'red'} x={-44} y={0} angle={90}/>
      <CardCover colorCard={'yellow'} x={-65} y={-45} angle={30}/>
      <Slogan y={-60}>{slogan}</Slogan>
      <Slap y={-195} x={313}/>
    </CoverStyle>
    {interactive && Array.from(content.cards).map((card, index) =>
      <React.Fragment key={index}>
        <Draggable id={Ids.languageCard('card' + index + '-' + titleGame)}
          z={zIndex(index)}
          onDragStart={() => setCurrentCard(index)}>
          <GameCard content={card.name} language={content.language}/>
        </Draggable>

      </React.Fragment>,
    )}
    {interactive &&
    <Draggable id={Ids.languageCard('end' + titleGame)} dragOver={{top: -60, bottom: 60, left: -100, right: 100}}
      z={-1}>
      <EndGame>
        <h1>{Finish}!</h1>
      </EndGame>
    </Draggable>}
  </Wrapper>;
};

LanguageCardsGame.defaultProps = {
  interactive: false,
  slogan: '',
};

LanguageCardsGame.propTypes = {
  interactive: PropTypes.bool,
  titleGame: PropTypes.string,
  slogan: PropTypes.string,
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    cards: (props, propName, componentName) => {
      const prop = props[propName];
      if (prop === undefined) {
        return new Error(`The prop \`content.cards\` is marked as required in \`${componentName}\`, but its value is \`undefined\`.`);
      }
      if (prop.length < 1) {
        return new Error(`\`${componentName}\` should contain at least one card information (${prop.length} received)`);
      } else {
        let i;
        for (i = 0; i < prop.length; i++) {
          if (prop[i].name === undefined) {
            return new Error(`The prop \`content.cards[${i}].name\` is marked as required in \`${componentName}\`, but its value is \`undefined\`.`);
          }
        }
      }
      return null;
    },
  }).isRequired,
};

const Wrapper = styled(Floating)`
  width: 340px;
  height: 200px;
  border-radius: 3px;
`;

const CoverStyle = styled(Floating)` 
  width: 340px;
  height: 200px;
  background: #e1e1e1;
  border-radius: 4px;
  display: ${({visible}) => (visible === false) ? 'block' : 'none'};
`;

const Title = styled.p`
  width: 225px;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  font-family: alibaba-sans;
  border-bottom: 5px red solid;
`;

const Slogan = styled.p`
  width: 241px;
  font-family: alibaba-sans;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(${({y}) => y}px);
`;

const Slap = styled.div`
  width: 25px;
  height: 50px;
  border-radius: 100% 0 0 100%;
  border-left: 2px black solid;
  border-bottom: 2px black solid;
  border-top: 2px black solid;
  transform: translate(${({x}) => x}px,${({y}) => y}px);
  background: #D0D3D4;
`;

const CardCover = styled.div`
  width: 59.2px;
  height: 35px;
  background: ${({colorCard}) => (colorCard === 'blue') ? 'skyblue' : (colorCard === 'red') ? 'red' : '#F1C40F'};
  border-radius: 3px;
  border: 1px black solid;
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(${({angle}) => angle}deg) translate(${({x}) => x}px,${({y}) => y}px);
`;

const EndGame = styled(Floating)`
  width: 340px;
  height: 200px;
  font-family: alibaba-sans;
  background: #e1e1e1;
  border: 5px #283a4e solid;
  background-size: cover;
  border-radius: 10px;
  color: #000;
  text-transform: uppercase;
  padding: auto;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

export default LanguageCardsGame;
