import React, {useEffect, useState} from 'react';
import {Floating} from './Floating';
import {CircularProgress} from '@material-ui/core';
import styled from 'styled-components';
import * as Proptype from 'prop-types';
import {env} from '../../env';

const Progress = ({timeout}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, timeout);
    return () => clearTimeout(timer);
  });

  return <>
    <Wrapper visible={visible}>
      <Floating x={50} y={50}>
        <CircularProgress disableShrink/>
      </Floating>
    </Wrapper>
  </>;
};

Progress.defaultProps = {
  timeout: env.PROGRESS_TIMEOUT,
};

Progress.propTypes = {
  timeout: Proptype.number,
};

const Wrapper = styled(Floating)`
    width: 100%;
    height: 100%;
    background-color: ${({visible}) => visible ? 'white' : 'none'};
    opacity: 50%;
`;

export default Progress;
