import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Display = ({fSize, value}) => {
  return <Wrapper fSize={fSize}>
    {value}
  </Wrapper>;
};
Display.defaultProps = {
  fSize: 36,
};

Display.propTypes = {
  fSize: PropTypes.string,
  value: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  width: 280px;
  height: 60px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  background: #a3b2a3;
  border: 2px #383838 solid;
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  font-size: ${({fSize}) => fSize}px ;
  font-family: Stopwatch W00 Regular;
  padding-right: 7px;
`;

export default Display;
