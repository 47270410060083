import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import TransactionStocksTable from './StockTransactionTable';
import {Button, ButtonGroup, Grid} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import {Actions, ActionsMenu} from './ActionsMenu';
import AccountSummary from './AccountSummary';
import PropTypes from 'prop-types';

const StockTransactions = ({accountId}) => {
  pageViewGA();

  const {
    data: transactionsData,
    loading: loadingTransactions,
    error: getTransactionsFailed,
    refresh: refreshTransactions,
  } = useRetrieve(Endpoints.stockTransactions(accountId));

  const {
    data: accountSummaryData,
    loading: loadingAccountSummary,
    error: getAccountSummaryFailed,
    refresh: refreshAccountSummary,
  } = useRetrieve(Endpoints.accountSummary(accountId));

  const {
    data: movementsData,
    loading: loadingMovements,
    error: getMovementsFailed,
    refresh: refreshMovements,
  } = useRetrieve(Endpoints.accountMovements([Actions.DEPOSIT, Actions.WITHDRAW], accountId));

  const onActionUpdated = (action) => {
    if (action === Actions.BUY || action === Actions.SELL || action === Actions.UNDO || action === Actions.DIVIDEND) {
      refreshTransactions();
    } else if (action === Actions.DEPOSIT || action === Actions.WITHDRAW) {
      refreshMovements();
    }
    refreshAccountSummary();
  };

  return <>
    <Grid container spacing={1}>
      <Grid item xs={12} md={2}>
        <ButtonGroup orientation={'vertical'} variant={'contained'}>
          <Button
            variant="contained" color="primary"
            onClick={async () => await window.location.assign(Endpoints.stockJournalPage())}>
            Journal</Button>
          <Button
            variant="contained" color="primary"
            onClick={async () => await window.location.assign(Endpoints.stockProfitSummaryPage())}>
            Profit History</Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={false} sm={1} md={true}/>
      <Grid item xs={12} sm={10} md={9}>
        <ActionsMenu onUpdate={onActionUpdated} accountId={accountId}/>
      </Grid>
      <Grid item xs={false} sm={1} md={true}/>
      <Grid item xs={12}>
        {accountSummaryData && <AccountSummary data={accountSummaryData}/>}
      </Grid>
      <Grid item xs={12}>
        {transactionsData && movementsData &&
        <TransactionStocksTable
          transactionsData={transactionsData}
          movementsData={movementsData}/>}
      </Grid>
    </Grid>
    { // TODO: create health-check in be. Only block all page on a healthcheck.
      //       other transaction should only block their specific elements
      (loadingTransactions || loadingMovements || loadingAccountSummary) && <Progress/>}
    { // TODO: modify ErrorHandler to handle all errors. Multiple modals are appearing
      getTransactionsFailed && <ErrorHandler error={getTransactionsFailed}/>}
    {getAccountSummaryFailed && <ErrorHandler error={getAccountSummaryFailed}/>}
    {getMovementsFailed && <ErrorHandler error={getMovementsFailed}/>}
  </>;
};

StockTransactions.defaultProps = {};

StockTransactions.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default StockTransactions;
