import React from 'react';
import {Endpoints} from '../endpoints';
import {env} from '../env';

export const userContext = React.createContext({user: {}});

export default function useToken(op) {
  const openPaths = ['/', ...op.map((path) => `${env.PUBLIC_URL}${path}`)];
  const getToken = () => {
    const tokenString = window.sessionStorage.getItem('token');
    return tokenString == null ? '' : JSON.parse(tokenString).token;
  };

  const token = getToken();
  let authenticationRequired = true;

  const currentLocation = window.location.pathname;
  if (!token && !openPaths.includes(currentLocation) && !currentLocation.includes(Endpoints.logInPage())) {
    window.sessionStorage.setItem('destination', currentLocation);
    window.location.assign(Endpoints.logInPage());
  } else if (token && currentLocation.includes(Endpoints.logInPage())) {
    authenticationRequired = false;
    const destination = window.sessionStorage.getItem('destination');
    if (destination != null) window.location.assign(destination);
    else window.location.assign(Endpoints.basePath());
  } else if (currentLocation.includes(Endpoints.logOutPage())) {
    authenticationRequired = false;
    window.sessionStorage.clear();
    window.history.back();
  } else {
    authenticationRequired = false;
  }

  const saveToken = (userToken) => {
    window.sessionStorage.setItem('token', JSON.stringify(userToken));
    const destination = window.sessionStorage.getItem('destination');
    if (destination != null) window.location.assign(destination);
    else window.location.assign(Endpoints.basePath());
  };

  return {
    authenticationRequired,
    token,
    setToken: saveToken,
    userContext,
  };
}
