const getVariable = (name) => {
  if (process.env[`REACT_APP_${name}`]) {
    return process.env[`REACT_APP_${name}`];
  } else if (process.env[name]) {
    return process.env[name];
  } else if (window.AppConfig !== undefined && window.AppConfig[name] !== undefined) {
    return window.AppConfig[name];
  } else {
    return '';
  }
};

export const env = {
  SERVER_URL: getVariable('SERVER_URL'),
  PUBLIC_URL: getVariable('PUBLIC_URL'),
  PROGRESS_TIMEOUT: Number.parseInt(`0${getVariable('PROGRESS_TIMEOUT')}`),
  MOCKED: getVariable('MOCKED'),
};
