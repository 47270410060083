import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = ({action, value, color, bg, fSize}) => {
  return <Wrapper type={'button'} onClick={action} value={value} color={color} bg={bg} fSize={fSize}/>;
};

Button.propTypes = {
  action: PropTypes.func,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  bg: PropTypes.string,
  fSize: PropTypes.string,
};

const Wrapper = styled.input`
  margin: auto;
  width: 94%;
  height: 90%;
  color: ${({color}) => color};
  border-radius: 15px;
  background: ${({bg}) => bg};
  border: 0px solid;
  font-size: ${({fSize}) => fSize};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    background: ${({bg}) => bg}95;
  }
`;

export default Button;
