import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BoardSquare from './BoardSquare';

const Chessboard = ({interactive, board}) => {
  const getXYPosition = (index) => {
    const x = index % 8;
    const y = Math.abs(Math.floor(index / 8) - 7);
    return {x, y};
  };

  const isBlack = (index) => {
    const {x, y} = getXYPosition(index);
    return (x + y) % 2 !== 1;
  };

  const getPosition = (index) => {
    const {x, y} = getXYPosition(index);
    const letter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'][x];
    return `${letter}${y + 1}`;
  };

  return <Board>
    {board.flat().map((piece, index) =>
      <Square key={index}>
        <BoardSquare position={getPosition(index)}
          index={index}
          interactive={interactive}
          piece={piece}
          black={isBlack(index)}/>
      </Square>,
    )}
  </Board>;
};

Chessboard.propTypes = {
  board: PropTypes.array.isRequired,
  interactive: PropTypes.bool,
};

const Board = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background: white;
`;

const Square = styled.div`
  width: 62.5px;
  height: 62.5px;
`;

export default Chessboard;
