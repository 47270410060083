import React, {useContext} from 'react';
import styled from 'styled-components';
import {Card, Grid} from '@material-ui/core';
import AdminContext from './AdminEngine';

const CreateComponentView = () => {
  const {isCreating} = useContext(AdminContext);

  return <Grid container spacing={2} justify={'center'}>
    <Grid item xs={12}>
      <Title>Create Component</Title>
    </Grid>
    <Grid item xs={4}>
      <ComponentBtn
        onClick={() => {
          isCreating('businessCard');
        }}
      >
        Business Card
      </ComponentBtn>
    </Grid>
  </Grid>;
};

const Title = styled.h1`
  padding-top: 10px;
`;

const ComponentBtn = styled(Card)`
  padding: 7px;
  font-size: 20px;
  background: #3498DB;
  border: 2px black solid; 
  
  :hover {
    cursor: pointer;
    background: #2E86C1;
  }
`;

export default CreateComponentView;
