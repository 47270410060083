import React from 'react';
import {Grid, InputAdornment, TextField} from '@material-ui/core';
import {ColoredButton} from '../../Common/Components/styledComponents';
import useFormController from '../../Common/Services/useFormController';
import {Endpoints} from '../../endpoints';
import Progress from '../../Common/Components/Progress';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {grey} from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import {Actions} from './ActionsMenu';

const DepositInAccount = ({onDeposit, accountId, dataSync: {getDefault, handleData, reset}}) => {
  const {
    currentValues,
    loading: buyingStock,
    error: buyStockFailed,
    postForm: buyTransaction,
    setField,
  } = useFormController(Endpoints.depositInAccount(accountId), {
    currencyCode: 'PHP',
    depositAmount: '',
    dateDeposited: (new Date()).toISOString().split('T')[0],
  }, getDefault(Actions.DEPOSIT), () => {
    onDeposit();
    reset();
  });

  const onSetField = (field, value) => {
    handleData(field, value);
    setField(field, value);
  };

  return <ImproveNumberFormatting>
    <Box m={2} p={1} bgcolor={grey[100]}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={9}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    label="Price"
                    name="Price"
                    type="number"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position={'start'}>{currentValues.currencyCode}</InputAdornment>,
                    }}
                    InputLabelProps={{shrink: true}}
                    value={currentValues.depositAmount}
                    onChange={(e) => {
                      onSetField('depositAmount', e.target.value);
                    }}/></Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Date"
                    name="Date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={currentValues.dateDeposited}
                    onChange={(e) => {
                      onSetField('dateDeposited', e.target.value);
                    }}/></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <ColoredButton
                variant="contained" colored={Actions.getColor(Actions.DEPOSIT)}
                onClick={buyTransaction}>Deposit</ColoredButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    {buyingStock && <Progress/>}
    {buyStockFailed && <ErrorHandler error={buyStockFailed}/>}
  </ImproveNumberFormatting>;
};

DepositInAccount.defaultProps = {};

DepositInAccount.propTypes = {
  onDeposit: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  dataSync: PropTypes.shape({
    getDefault: PropTypes.func.isRequired,
    handleData: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }).isRequired,
};

const ImproveNumberFormatting = styled.div`
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}`;

export default DepositInAccount;
