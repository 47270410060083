import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Floating} from '../../Common/Components/Floating';
import Flippable from './Flippable';
import styled from 'styled-components';
import {AnimationBehaviorContext} from './Behavior/AnimationBehaviorHandler';
import {AnimationType, defaultAnimationBehavior, RunningAnimation} from './Behavior/behaviorDefinitions';

const Pageable = ({id, startPaged, onPaged, onPagingStart, externalTrigger, pageWidth, children}) => {
  const animationContext = useContext(AnimationBehaviorContext);
  const animation = new RunningAnimation(AnimationType.Paging, id);
  const ignoredFlipAnimation = new RunningAnimation(AnimationType.Flip, `Flippable-${id}`);

  return <AnimationBehaviorContext.Provider value={{
    defaultBehavior: defaultAnimationBehavior,
    allowed: (a) => animationContext.allowed(a.equals(ignoredFlipAnimation) ? animation : a),
    start: (a) => animationContext.start(a.equals(ignoredFlipAnimation) ? animation : a),
    stop: (a) => animationContext.stop(a.equals(ignoredFlipAnimation) ? animation : a),
  }}>
    <Flippable id={`Flippable-${id}`} startFlipped={startPaged} onFlipped={onPaged} onFlipStart={onPagingStart}
      externalTrigger={externalTrigger}>
      <PagedSize pageWidth={pageWidth}><Floating x={100}>{children[0]}</Floating></PagedSize>
      <PagedSize pageWidth={pageWidth}><Floating x={0}>{children[1] || ''}</Floating></PagedSize>
    </Flippable>
  </AnimationBehaviorContext.Provider>;
};

Pageable.defaultProps = {
  onPaged: () => null,
  onPagingStart: () => null,
  externalTrigger: null,
  delay: 0,
  startPaged: false,
};

Pageable.propTypes = {
  id: PropTypes.string.isRequired,
  onPaged: PropTypes.func,
  onPagingStart: PropTypes.func,
  startPaged: PropTypes.bool,
  pageWidth: PropTypes.number.isRequired,
  externalTrigger: PropTypes.shape({current: PropTypes.instanceOf(HTMLElement)}),
  children: (props, propName, componentName) => {
    const prop = props[propName];
    const count = React.Children.count(prop);
    if (count === 0 || count > 2) {
      return new Error(`\`${componentName}\` should contain at least one and maximum two children (${count} received)`);
    }
  },
};

const PagedSize = styled(Floating)`
    width: ${({pageWidth}) => pageWidth}px;
`;


export default Pageable;
