import React from 'react';
import {Button, SnackbarContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Floating} from './Floating';
import styled from 'styled-components';

const ConfirmNotification = ({onAccept, onDecline, principalText, secondaryText}) => {
  return <Wrapper>
    <Floating z={4}>
      <SnackbarContent
        message={
          <div>
            <div>{principalText}
              <br/>{secondaryText}
            </div>
            <div>
              <Button color="secondary" onClick={() => onDecline()}>
                CANCEL
              </Button>
              <Button onClick={() => onAccept()} style={{color: 'white'}}>
                ACCEPT
              </Button>
            </div>
          </div>
        }/>
    </Floating>
  </Wrapper>;
};

ConfirmNotification.defaultProps = {
  principalText: 'Are you sure you want to do this?',
  secondaryText: 'This action is permanet.',
};

ConfirmNotification.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  principalText: PropTypes.string,
  secondaryText: PropTypes.string,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export default ConfirmNotification;
