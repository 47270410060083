import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AnimationsBehaviorType, AnimationType, defaultAnimationBehavior} from './behaviorDefinitions';
import {
  AnimationAlreadyRunningError,
  AnimationsIsNotRunning,
  DisabledAnimations,
  NoAnimationsWhenDragging,
} from './exceptions';
import {animationEventGA} from '../../../Common/Utils/analytics';

export const AnimationBehaviorContext = React.createContext(defaultAnimationBehavior);

const AnimationBehaviorHandler = ({behaviorType, children}) => {
  const [playingAnimations, setPlayingAnimations] = useState([]);
  const [enabled, setEnabled] = useState(true);

  const stateHandler = {
    checkAllowed: function(animation) {
      if (!enabled) return false;
      if (behaviorType === AnimationsBehaviorType.One) {
        const isAnimating = playingAnimations.length !== 0;
        return {allowed: !isAnimating, exception: new AnimationAlreadyRunningError(animation, playingAnimations)};
      }
      if (behaviorType === AnimationsBehaviorType.IndependentDrag) {
        const isDragging = playingAnimations.some((it) => it.animationType === AnimationType.Drag);
        return {allowed: !isDragging, exception: new NoAnimationsWhenDragging(animation, playingAnimations)};
      }
      const allowed = behaviorType !== AnimationsBehaviorType.NoAnimations;
      return {allowed: allowed, exception: !allowed ? new DisabledAnimations(animation) : null};
    },
    setStart: function(animation) {
      const result = this.checkAllowed(animation);
      if (!result.allowed) throw result.exception;
      animationEventGA(animation.animationType, animation.elementId);

      setPlayingAnimations([...playingAnimations.concat([animation])]);
    },
    setStop: (animation) => {
      if (!playingAnimations.some((a) => a.equals(animation))) {
        throw new AnimationsIsNotRunning(animation, playingAnimations);
      }
      const remainingAnimations = playingAnimations.filter((a) => !a.equals(animation));
      setPlayingAnimations([...remainingAnimations]);
    },
    runningAny: (animationType) => {
      return playingAnimations.some((a) => a.animationType === animationType);
    },
    enable: (flag) => {
      setEnabled(flag);
    },
  };

  return <AnimationBehaviorContext.Provider value={{
    ...defaultAnimationBehavior,
    behaviorType,
    stateHandler,
  }}>
    {children}
  </AnimationBehaviorContext.Provider>;
};

AnimationBehaviorHandler.defaultProps = {
  behaviorType: AnimationsBehaviorType.AllowAll,
};

AnimationBehaviorHandler.propTypes = {
  behaviorType: PropTypes.oneOf(Object.values(AnimationsBehaviorType).filter((it) => it !== AnimationsBehaviorType.NoHandler)),
  children: PropTypes.node.isRequired,
};

export default AnimationBehaviorHandler;
