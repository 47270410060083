import React from 'react';
import {Grid} from '@material-ui/core';
import {ColoredButton, simpleBorder} from '../../Common/Components/styledComponents';
import {Endpoints} from '../../endpoints';
import Progress from '../../Common/Components/Progress';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useDelete from '../../Common/Services/useDelete';
import {grey} from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import {Actions} from './ActionsMenu';

const UndoTransactionConfirmation = ({onDeleted, accountId}) => {
  const {
    deleting: deletingStock,
    error: buyStockFailed,
    delete: deleteTransaction,
  } = useDelete(Endpoints.lastTransaction(accountId), onDeleted);

  return <ImproveNumberFormatting>
    <Box m={2} p={1} bgcolor={grey[100]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} className={simpleBorder().root}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={10}>
              Are you sure you want to delete the last transaction you did?
            </Grid>
            <Grid item xs={12} md={2}>
              <ColoredButton variant="contained" colored={Actions.getColor(Actions.UNDO)}
                onClick={deleteTransaction}>Delete</ColoredButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    {deletingStock && <Progress/>}
    {buyStockFailed && <ErrorHandler error={buyStockFailed}/>}
  </ImproveNumberFormatting>;
};

UndoTransactionConfirmation.defaultProps = {};

UndoTransactionConfirmation.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
};

const ImproveNumberFormatting = styled.div`
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}`;

export default UndoTransactionConfirmation;
