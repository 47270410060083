import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Floating} from '../../Common/Components/Floating';

const BackSide = ({text}) => {
  return <Wrapper>
    <Floating>
      {text}
    </Floating>
  </Wrapper>;
};

BackSide.defaultProps = {
  text: '',
};

BackSide.propTypes = {
  text: PropTypes.string,
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background: url('resources/whiteCard.jpg');
    background-size: cover;
    background-color: white;
    background-color: lightyellow;
    border-radius: inherit;
`;

export default BackSide;
