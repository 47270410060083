import React from 'react';
import {Endpoints} from '../endpoints';
import {Floating} from '../Common/Components/Floating';
import useFormController from '../Common/Services/useFormController';
import {emptyFunction} from '../Common/Utils/dumbUtils';
import {colors, TextField} from '@material-ui/core';
import Progress from '../Common/Components/Progress';
import PropTypes from 'prop-types';
import ErrorHandler from '../Common/Components/ErrorHandler';
import {ColoredButton} from '../Common/Components/styledComponents';
import styled from 'styled-components';

const Login = ({setToken}) => {
  const {
    currentValues,
    loading: loggingIn,
    error: loginFailed,
    postForm: login,
    setField,
  } = useFormController(Endpoints.logIn(),
    {
      username: '',
      password: '',
    }, emptyFunction,
    (response) => setToken(response.data));

  return <Wrapper>
    <Floating>
      <TextField
        label="User"
        type="username"
        InputLabelProps={{shrink: true}}
        value={currentValues.username}
        onChange={(e) => {
          setField('username', e.target.value);
        }}/>
      <br/>
      <br/>
      <TextField
        label="Password"
        type="password"
        InputLabelProps={{shrink: true}}
        value={currentValues.password}
        onChange={(e) => {
          setField('password', e.target.value);
        }}/>
      <br/>
      <br/>
      <ColoredButton variant="contained" colored={colors.green} onClick={login}>Login</ColoredButton>
    </Floating>
    {loggingIn && <Progress/>}
    {loginFailed && <ErrorHandler error={loginFailed}/>}
  </Wrapper>;
};

Login.defaultProps = {
  setToken: emptyFunction(),
};

Login.propTypes = {
  setToken: PropTypes.func,
};


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default Login;
