import React from 'react';
import {pageViewGA} from '../../Common/Utils/analytics';
import {Container, Grid} from '@material-ui/core';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import Progress from '../../Common/Components/Progress';
import {Endpoints} from '../../endpoints';
import useRetrieve from '../../Common/Services/useRetrieve';
import StockTransactions from './StockTransactions';

const StockTransactionsPage = () => {
  pageViewGA();

  const {
    data: defaultAccount,
    loading: loadingDefaultAccount,
    error: getDefaultAccountFailed,
  } = useRetrieve(Endpoints.defaultAccount());

  return <Container>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1 className={'text-center'}>STOCK TRANSACTIONS</h1>
      </Grid>
      <Grid item xs={12}>
        {defaultAccount && <StockTransactions accountId={defaultAccount.id}/>}
      </Grid>
    </Grid>
    { // TODO: create health-check in be. Only block all page on a healthcheck.
      //       other transaction should only block their specific elements
      (loadingDefaultAccount) && <Progress/>}
    { // TODO: modify ErrorHandler to handle all errors. Multiple modals are appearing
      getDefaultAccountFailed && <ErrorHandler error={getDefaultAccountFailed}/>}
  </Container>;
};

StockTransactionsPage.defaultProps = {};

StockTransactionsPage.propTypes = {};

export default StockTransactionsPage;
