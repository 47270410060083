import React, {useState} from 'react';
import {ButtonGroup, Grid} from '@material-ui/core';
import {Undo} from '@material-ui/icons';
import BuyTransactionForm from './BuyTransactionForm';
import SellTransactionForm from './SellTransactionForm';
import useFormDataSync from '../Services/useFormDataSync';
import UndoTransactionConfirmation from './UndoTransactionConfirmation';
import {ColoredButton} from '../../Common/Components/styledComponents';
import * as PropType from 'prop-types';
import {green, grey, lightGreen, orange, red, yellow} from '@material-ui/core/colors';
import WithdrawFromAccountForm from './WithdrawFromAccountForm';
import DepositInAccount from './DepositInAccountForm';
import DividendTransactionForm from './DividendTransactionForm';

const Actions = {
  BUY: 'BUY',
  SELL: 'SELL',
  UNDO: 'UNDO',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  DIVIDEND: 'DIVIDEND',
  getColor: (action) => {
    return ActionColors[`${action}_COLOR`];
  },
};

const ActionColors = {
  BUY_COLOR: red,
  SELL_COLOR: green,
  UNDO_COLOR: grey,
  DEPOSIT_COLOR: yellow,
  WITHDRAW_COLOR: orange,
  DIVIDEND_COLOR: lightGreen,
};

const ActionsMenu = ({onUpdate, accountId}) => {
  const [action, setAction] = useState('');

  const {dataSync} = useFormDataSync();

  const onActionUpdate = async () => {
    await onUpdate(action);
    await setAction('');
  };

  const onActionClicked = (clickedAction) => {
    action === clickedAction ? setAction('') : setAction(clickedAction);
  };

  return <Grid container spacing={1}>
    <Grid item xs={12} sm={6}>
      <ButtonGroup variant="contained">
        <ColoredButton
          onClick={() => onActionClicked(Actions.DIVIDEND)}
          colored={Actions.getColor(Actions.DIVIDEND)}>Dividend</ColoredButton>
        <ColoredButton
          onClick={() => onActionClicked(Actions.BUY)}
          colored={Actions.getColor(Actions.BUY)}>Buy</ColoredButton>
        <ColoredButton
          onClick={() => onActionClicked(Actions.SELL)}
          colored={Actions.getColor(Actions.SELL)}>Sell</ColoredButton>
        <ColoredButton
          id={'UNDO'}
          onClick={() => onActionClicked(Actions.UNDO)}
          colored={Actions.getColor(Actions.UNDO)}><Undo/></ColoredButton>
      </ButtonGroup>
    </Grid>
    <Grid item xs={12} sm={6}>
      <ButtonGroup variant="contained">
        <ColoredButton
          onClick={() => onActionClicked(Actions.DEPOSIT)}
          colored={Actions.getColor(Actions.DEPOSIT)}>Deposit</ColoredButton>
        <ColoredButton
          onClick={() => onActionClicked(Actions.WITHDRAW)}
          colored={Actions.getColor(Actions.WITHDRAW)}>Withdraw</ColoredButton>
      </ButtonGroup>
    </Grid>
    <Grid item xs={action === '' ? false : 12}>
      {action === Actions.BUY &&
      <BuyTransactionForm onTransactionBought={onActionUpdate} accountId={accountId} dataSync={dataSync}/>}
      {action === Actions.SELL &&
      <SellTransactionForm onTransactionSold={onActionUpdate} accountId={accountId} dataSync={dataSync}/>}
      {action === Actions.DIVIDEND &&
      <DividendTransactionForm onDividendGotten={onActionUpdate} accountId={accountId} dataSync={dataSync}/>}
      {action === Actions.UNDO && <UndoTransactionConfirmation onDeleted={onActionUpdate} accountId={accountId}/>}
      {action === Actions.DEPOSIT &&
      <DepositInAccount onDeposit={onActionUpdate} accountId={accountId} dataSync={dataSync}/>}
      {action === Actions.WITHDRAW &&
      <WithdrawFromAccountForm onWithDraw={onActionUpdate} accountId={accountId} dataSync={dataSync}/>}
    </Grid>
  </Grid>;
};

ActionsMenu.defaultProps = {};

ActionsMenu.propTypes = {
  onUpdate: PropType.func.isRequired,
  accountId: PropType.string.isRequired,
};

export {
  ActionsMenu,
  Actions,
};
