export const defaultAccount = {
  id: '50b18c3f-0e7d-4810-91a6-d73703bae3a3',
};

export const accountSummary = {
  accountId: defaultAccount.id,
  accountName: 'DEFAULT',
  amount: 12000,
  currency: 'PHP',
  lastDepositOrWithdrawalDate: '2020-12-07',
};

export const accountDepositRow = {
  movementType: 'DEPOSIT',
  date: '2020-01-01',
  amount: 100.0,
  currency: 'AED',
};

export const accountWithdrawRow = {
  movementType: 'WITHDRAW',
  date: '2020-01-04',
  amount: 100.0,
  currency: 'AED',
};

export const stockBuyTransactionRow = {
  transactionType: 'BUY',
  stockCode: 'ABC',
  price: 10.0,
  numberOfShares: 1,
  date: '2020-01-02',
  fee: 1.0,
  totalMovement: 11.0,
  adjustedPrice: 11.0,
  currency: 'AED',
};

export const stockSellTransactionRow = {
  transactionType: 'SELL',
  stockCode: 'ABC',
  price: 10.0,
  numberOfShares: 1,
  date: '2020-01-03',
  fee: 1.0,
  totalMovement: 9.0,
  adjustedPrice: 9.0,
  currency: 'AED',
};

export const stockDividendTransactionRow = {
  transactionType: 'DIVIDEND',
  stockCode: 'ABC',
  price: 10.0,
  numberOfShares: 1,
  date: '2020-01-03',
  fee: 1.0,
  totalMovement: 9.0,
  adjustedPrice: 9.0,
  currency: 'AED',
};

export const stockJournalRow = {
  stockCode: 'ABC',
  averagePrice: 1.0,
  numberOfShares: 1,
  boughtDate: '2020-01-01',
  marketValue: 10.0,
  currency: 'AED',
};

export const profitSummaryRow = {
  stockCode: 'ABC',
  averageBoughtPrice: 10.0,
  averageSoldPrice: 9.0,
  numberOfShares: 1,
  profit: 1.0,
  profitPercentage: 10.0,
  currency: 'AED',
};

export const apiError = {
  error: 'api error',
  status: 'status',
  message: 'error message',
  errors: {random: 'error'},
};
