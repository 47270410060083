import React, {useContext} from 'react';
import {Grid} from '@material-ui/core';
import ComponentButton from './ComponentButton';
import styled from 'styled-components';
import AdminContext from './AdminEngine';
import ErrorHandler from '../Common/Components/ErrorHandler';
import Progress from './../Common/Components/Progress';

const ComponentSelector = () => {
  const {
    componentsData,
    loadingComponents,
    getComponentsFailed,
    selectComponent,
    selectedComponent,
    creatingComponent,
  } = useContext(AdminContext);

  return <Wrapper>
    <Grid container spacing={1}>
      {creatingComponent && <Grid item xs={12}>
        <CreatingComponent>Creating...</CreatingComponent>
      </Grid>}
      {componentsData && componentsData.map((component, index) =>
        <Grid key={index} item xs={12}>
          <ComponentButton
            id={component.componentId}
            onClick={(selectedComponent === component.componentId) ?
              () => selectComponent(null) :
              () => selectComponent(component.componentId)
            }
            componentName={component.componentName}
            componentType={component.componentType}
            isSelected={selectedComponent === component.componentId}
          />
        </Grid>,
      )}
    </Grid>
    {loadingComponents && <Progress/>}
    {getComponentsFailed && <ErrorHandler error={getComponentsFailed}/>}
  </Wrapper>;
};

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background: #f2f2f2;
  padding: 10px;
`;

const CreatingComponent = styled.div`
  background: white;
  border: 2px green solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: progress;
  padding: 5px;
  font-size: 20px;
`;

export default ComponentSelector;
