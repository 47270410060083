import React from 'react';
import {Grid, InputAdornment, TextField} from '@material-ui/core';
import {ColoredButton} from '../../Common/Components/styledComponents';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useFormController from '../../Common/Services/useFormController';
import {Endpoints} from '../../endpoints';
import Progress from '../../Common/Components/Progress';
import ErrorHandler from '../../Common/Components/ErrorHandler';
import PropTypes from 'prop-types';
import useRetrieve from '../../Common/Services/useRetrieve';
import styled from 'styled-components';
import {grey} from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {formatDouble} from '../../Common/Utils/formatter';
import {Actions as Action, Actions} from './ActionsMenu';

const BuyTransactionForm = ({onTransactionBought, accountId, dataSync: {getDefault, handleData, reset}}) => {
  const {
    currentValues,
    loading: buyingStock,
    error: buyStockFailed,
    postForm: buyTransaction,
    setField,
  } = useFormController(Endpoints.buyStockTransaction(accountId), {
    currencyCode: 'PHP',
    stockCode: '',
    buyPrice: '',
    numberOfShares: '',
    brokerageFee: '',
    dateBought: (new Date()).toISOString().split('T')[0],
  }, getDefault(Actions.BUY), () => {
    onTransactionBought();
    reset();
  });
  const {
    data: stocks,
    loading: loadingStocks,
    error: getStocksFailed,
  } = useRetrieve(Endpoints.stockCodes());

  const onSetField = (field, value) => {
    handleData(field, value);
    setField(field, value);
  };

  const totalValue = (parseFloat(currentValues.buyPrice) * parseInt(currentValues.numberOfShares)) + parseFloat(currentValues.brokerageFee);
  return <ImproveNumberFormatting>
    <Box m={2} p={1} bgcolor={grey[100]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} lg={10}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    name="Stock"
                    freeSolo
                    autoSelect
                    disableClearable
                    options={stocks ? stocks : ['']}
                    value={currentValues.stockCode}
                    onInputChange={(event, newValue) => {
                      onSetField('stockCode', newValue.toUpperCase());
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label={'Stock'}/>}
                  /></Grid>
                <Grid item xs={3} md={2}>
                  <TextField
                    label="# Shares"
                    name="# Shares"
                    type="number"
                    InputLabelProps={{shrink: true}}
                    value={currentValues.numberOfShares}
                    onChange={(e) => {
                      onSetField('numberOfShares', e.target.value);
                    }}/></Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    label="Price"
                    name="Price"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position={'start'}>{currentValues.currencyCode}</InputAdornment>,
                    }}
                    InputLabelProps={{shrink: true}}
                    value={currentValues.buyPrice}
                    onChange={(e) => {
                      onSetField('buyPrice', e.target.value);
                    }}/></Grid>
                <Grid item xs={5} md={2}>
                  <TextField
                    label="Brokerage Fee"
                    name="Brokerage Fee"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position={'start'}>{currentValues.currencyCode}</InputAdornment>,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={currentValues.brokerageFee}
                    onChange={(e) => {
                      onSetField('brokerageFee', e.target.value);
                    }}/></Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Date"
                    name="Date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={currentValues.dateBought}
                    onChange={(e) => {
                      onSetField('dateBought', e.target.value);
                    }}/></Grid>
              </Grid>
              <Grid item xs={12} lg={10}>
                <Typography align={'right'}>
                  {totalValue ? `TOTAL COST: ${formatDouble(totalValue)}` : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={2}>
              <ColoredButton variant="contained" colored={Action.getColor(Action.BUY)}
                onClick={buyTransaction}>Buy</ColoredButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    {buyingStock || loadingStocks && <Progress/>}
    {buyStockFailed && <ErrorHandler error={buyStockFailed}/>}
    {getStocksFailed && <ErrorHandler error={getStocksFailed}/>}
  </ImproveNumberFormatting>;
};

BuyTransactionForm.defaultProps = {};

BuyTransactionForm.propTypes = {
  onTransactionBought: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  dataSync: PropTypes.shape({
    getDefault: PropTypes.func.isRequired,
    handleData: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }).isRequired,
};

const ImproveNumberFormatting = styled.div`
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}`;

export default BuyTransactionForm;
