import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Logo = ({name, mote}) => {
  return (
    <Wrapper>
      <Label id={'logo-label'}>{mote}</Label>
      <div id={'name-label'}>{name}</div>
    </Wrapper>
  );
};

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  mote: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
    padding: 15px;
    border: solid black 1px;
    border-radius: 50%;
    background: url('resources/blueCard.jpg');
    background-size: cover;
    background-color: #3b2779;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: cursive;
    font-size: 12px;
    color: white;
`;

const Label = styled.div`
    font-size: 40px;
`;

export default Logo;
